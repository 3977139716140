import {
  getCompanyFeatures,
  GetCompanyFeatures200,
} from "@/generated/api/users";
import { queryOptions, useQuery } from "@tanstack/react-query";
import React from "react";
import { z } from "zod";
import { planTypeSchema } from "../services/user";
import { Failed } from "../components/info/info";

type Props = {
  companyId: number;
  children: (features: GetCompanyFeatures200) => React.ReactNode;
};

const planFeaturesSchema = z.record(
  z.union([
    z.literal("MARKET_NEWS"),
    z.literal("QUICK_TRADING"),
    z.literal("STOCK_EXCHANGE"),
    z.literal("PURCHASE_PRICES"),
    z.literal("STORAGES"),
    z.literal("TRADING"),
    z.literal("TRANSACTIONS_ORDERS"),
  ]),
  z.object({
    menu: z.boolean(),
    planType: planTypeSchema,
    planId: z.number(),
  }),
);

const FeatureProvider = ({ children, companyId }: Props) => {
  const {
    data: features,
    error,
    status,
  } = useQuery(companyFeaturesQuery({ companyId }));

  if (status === "error") {
    return <Failed error={error} />;
  }

  if (status === "pending") {
    return null;
  }

  return <>{children(planFeaturesSchema.parse(features))}</>;
};

const companyFeaturesQuery = ({ companyId }: { companyId: number }) =>
  queryOptions({
    queryKey: ["features", companyId],
    queryFn: ({ signal }) => getCompanyFeatures(companyId, { signal }),
    staleTime: Infinity,
  });

type Features = z.infer<typeof planFeaturesSchema>;

export { FeatureProvider };
export type { Features };
