import { Strategy } from "../reports.flow";

class FullStrategy implements Strategy {
  public isMasked() {
    return false;
  }

  public getDummyReport(): ReturnType<Strategy["getDummyReport"]> {
    return;
  }
}

export { FullStrategy };
