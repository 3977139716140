import { RouteObject } from "react-router-dom";
import * as Report from "./report/report";
import { Reports, ReportsParams, path as reportsPath } from "./reports/reports";
import {
  StockExchange,
  StockExchangeParams,
  path as stockExchangePath,
} from "./stock-exchange/stock-exchange";
import { ErrorPage } from "@/common/components/error-page/error-page";
import { ErrorBoundary } from "react-error-boundary";
import { Failed } from "@/common/components/info/info";
import { Suspense } from "react";
import { Mode } from "@/domain/plan/reports/reports.flow";

const getReportsRoutes = (mode: Mode): RouteObject[] => [
  {
    path: reportsPath,
    element: (
      <ReportsParams>
        <Reports />
      </ReportsParams>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Report.path,
        element: (
          <ErrorBoundary
            fallbackRender={({ error }) => <Failed error={error} />}
          >
            <Suspense fallback={<Report.Skeleton />}>
              <Report.Report mode={mode} />
            </Suspense>
          </ErrorBoundary>
        ),
      },
    ],
  },
];

const stockExchangeRoutes: RouteObject[] = [
  {
    path: stockExchangePath,
    element: (
      <StockExchangeParams>
        <StockExchange />
      </StockExchangeParams>
    ),
  },
];

export { stockExchangeRoutes, getReportsRoutes };
