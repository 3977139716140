import { Trans } from "@lingui/react/macro";
import { Spinner } from "@/common/components/spinner/spinner";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Role } from "@/common/services/user";
import { useUserRoleMutation } from "./user-role-action.api";
import { resetForm } from "@/api/form-reset";

type Fields = {
  role: Role;
};

const UserRoleAction = ({
  initialRole,
  userCompanyRelationId,
}: {
  initialRole: Role;
  userCompanyRelationId: number;
}) => {
  const form = useForm<Fields>({
    defaultValues: {
      role: initialRole,
    },
  });
  const queryClient = useQueryClient();
  const userRoleMutation = useUserRoleMutation();

  const handleSubmit = (data: Fields) => {
    userRoleMutation.mutate(
      { userCompanyRelationId, role: data.role },
      {
        onError: resetForm(form),
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <form className="flex items-center gap-x-2">
      <select
        disabled={userRoleMutation.status === "pending"}
        className="mt-2 w-[110px] rounded-lg border"
        {...form.register("role", {
          onChange: async () => {
            await form.handleSubmit(handleSubmit)();
          },
        })}
      >
        <option value="USER">
          <Trans>Uživatel</Trans>
        </option>
        <option value="STATUTORY">
          <Trans>Statutár</Trans>
        </option>
      </select>
      {userRoleMutation.status === "pending" ? (
        <Spinner className="!h-4 !w-4" withHint={false} />
      ) : null}
    </form>
  );
};

export { UserRoleAction };
