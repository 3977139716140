import { Trans } from "@lingui/react/macro";
import { primary } from "@/common/components/button/button";
import { Link, generatePath, resolvePath, useParams } from "react-router-dom";
import { path as orderPath } from "../../../transactions-and-orders/transactions-and-orders.order";
import { path as contractPath } from "../../../transactions-and-orders/transactions-and-orders.contract";
import { path as transactionsAndOrdersPath } from "../../../transactions-and-orders/transactions-and-orders";
import invariant from "tiny-invariant";
import { getAbsolutePath } from "@/routes/routes.utils";

const ContractLink = () => {
  const { contractId } = useParams();
  invariant(contractId);

  return (
    <Link
      to={resolvePath(
        generatePath(contractPath, { contractId }),
        getAbsolutePath(transactionsAndOrdersPath),
      )}
      replace
      className={primary}
    >
      <Trans>Přejít na detail transakce</Trans>
    </Link>
  );
};

const OrderLink = () => {
  const { offerId, orderId } = useParams();
  invariant(offerId);
  invariant(orderId);

  return (
    <Link
      to={resolvePath(
        generatePath(orderPath, { offerId, orderId }),
        getAbsolutePath(transactionsAndOrdersPath),
      )}
      replace
      className={primary}
    >
      <Trans>Přejít na detail příkazu</Trans>
    </Link>
  );
};

export { ContractLink, OrderLink };
