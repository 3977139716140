/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Clever Analytics Notifications Public API
 * OpenAPI spec version: 1.0.0
 */
import { notificationsInstance } from '../../api/client';
export type SendNotificationBody = NewReportNotificationRequest | TestUserNotificationRequest;

export type PushCreateRequestPlatform = typeof PushCreateRequestPlatform[keyof typeof PushCreateRequestPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushCreateRequestPlatform = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WEB: 'WEB',
} as const;

export interface PushCreateRequest {
  platform: PushCreateRequestPlatform;
  token: string;
}

export type TestUserNotificationRequestAllOfPayload = {[key: string]: string};

export type TestUserNotificationRequestAllOf = {
  body?: string;
  image?: string;
  link?: string;
  payload?: TestUserNotificationRequestAllOfPayload;
  title?: string;
  user?: string[];
};

export type TestUserNotificationRequest = NotificationRequest & TestUserNotificationRequestAllOf;

export type NotificationRequestType = typeof NotificationRequestType[keyof typeof NotificationRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationRequestType = {
  TEST: 'TEST',
  NEW_REPORT: 'NEW_REPORT',
} as const;

export interface NotificationRequest {
  type: NotificationRequestType;
}

export type NewReportNotificationRequestAllOf = {
  body?: string;
  image?: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?: string;
  link?: string;
  title?: string;
};

export type NewReportNotificationRequest = NotificationRequest & NewReportNotificationRequestAllOf;

export type NotificationToType = typeof NotificationToType[keyof typeof NotificationToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationToType = {
  TEST: 'TEST',
  NEW_REPORT: 'NEW_REPORT',
} as const;

export type NotificationToPayload = {[key: string]: string};

export interface NotificationTo {
  archivedAt?: string;
  body: string;
  createdAt: string;
  id: number;
  image?: string;
  payload?: NotificationToPayload;
  title: string;
  type: NotificationToType;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary Archive notification by it`s ID
 */
export const archiveNotification = (
    id: number,
 options?: SecondParameter<typeof notificationsInstance>,) => {
      return notificationsInstance<NotificationTo>(
      {url: `/can-notifications/api/notification/${id}/archive`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Send a notification
 */
export const sendNotification = (
    sendNotificationBody: SendNotificationBody,
 options?: SecondParameter<typeof notificationsInstance>,) => {
      return notificationsInstance<void>(
      {url: `/can-notifications/internal/api/notification`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: sendNotificationBody
    },
      options);
    }
  
/**
 * @summary Create a new push token registration
 */
export const create = (
    pushCreateRequest: PushCreateRequest,
 options?: SecondParameter<typeof notificationsInstance>,) => {
      return notificationsInstance<void>(
      {url: `/can-notifications/api/push`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pushCreateRequest
    },
      options);
    }
  
/**
 * @summary List all notifications for a user
 */
export const getNotifications = (
    
 options?: SecondParameter<typeof notificationsInstance>,) => {
      return notificationsInstance<NotificationTo[]>(
      {url: `/can-notifications/api/notification`, method: 'GET'
    },
      options);
    }
  
export type ArchiveNotificationResult = NonNullable<Awaited<ReturnType<typeof archiveNotification>>>
export type SendNotificationResult = NonNullable<Awaited<ReturnType<typeof sendNotification>>>
export type CreateResult = NonNullable<Awaited<ReturnType<typeof create>>>
export type GetNotificationsResult = NonNullable<Awaited<ReturnType<typeof getNotifications>>>
