import { t } from "@lingui/core/macro";
import Search from "@icons/search.svg?url";
import { InputField } from "@/common/components/form/input-field/input-field";
import { useSuspenseQuery } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import invariant from "tiny-invariant";
import { Fields } from "./storage-form";
import { postalCodesQuery } from "@/routes/shared/api/get-postal-codes";

const PostalCodeField = ({ label }: { label: React.ReactNode }) => {
  const firstRun = React.useRef(true);
  const form = useFormContext<Fields>();
  const postalCodes = useSuspenseQuery(postalCodesQuery());
  const postalCodeField = form.register("postalCode");
  const postalCodeValue = form.watch("postalCode");
  const { setSuggestionsVisible, suggestions } = usePostalCodeField();

  // just make sure we have the postal code label
  React.useEffect(() => {
    if (!firstRun.current) {
      return;
    }
    firstRun.current = false;
    const postalCode = postalCodes.data.find(
      (pc) => pc.code === postalCodeValue,
    );
    if (postalCodeValue.split(" ").length === 1 && postalCode) {
      form.setValue("postalCode", postalCode.label);
    }
  }, [form, postalCodeValue, postalCodes.data]);

  return (
    <InputField
      autoComplete="off"
      placeholder={t`Vyhledat`}
      label={label}
      name="postalCode"
      options={{
        required: t`Vyplňte prosím PSČ`,
        validate: (value: string) =>
          postalCodes.data.some((pc) =>
            pc.label.toLowerCase().includes(value.toLowerCase()),
          ) || t`Vyberte PSČ ze seznamu`,
      }}
      style={{
        // eslint-disable-next-line lingui/no-unlocalized-strings
        background: `url(${Search}) right 16px center no-repeat`,
      }}
      onChange={async (e) => {
        await postalCodeField.onChange(e);
        setSuggestionsVisible(true);
      }}
      onFocus={() => {
        if (suggestions.length > 0) {
          setSuggestionsVisible(true);
        }
      }}
    />
  );
};

const Suggestions = ({ className }: { className?: string }) => {
  const { selectSuggestion, suggestionsVisible, suggestions } =
    usePostalCodeField();
  return suggestionsVisible && suggestions.length > 0 ? (
    <ul
      className={`absolute flex max-h-[140px] w-full flex-col overflow-y-auto rounded-lg bg-white shadow-can-light-box ${className}`}
    >
      {suggestions.map((match) => (
        <li
          key={match.label}
          className="flex border-b border-b-can-silver-cloud"
        >
          <button
            className="flex-grow px-4 py-2 text-left"
            onClick={() => selectSuggestion(match.label)}
          >
            {match.label}
          </button>
        </li>
      ))}
    </ul>
  ) : null;
};

type TContext = {
  setSuggestionsVisible: (v: boolean) => void;
  suggestionsVisible: boolean;
  suggestions: Array<{
    code: string;
    name: string;
    label: string;
    latitude: number;
    longitude: number;
  }>;
  selectSuggestion: (suggestion: string) => void;
};

const Context = React.createContext<TContext | undefined>(undefined);

const usePostalCodeField = () => {
  const context = React.useContext(Context);

  invariant(
    context,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "Context only available within <PostalCodeFieldContext />",
  );

  return context;
};

const Provider = ({ children }: React.PropsWithChildren) => {
  const form = useFormContext<Fields>();
  const postalCodes = useSuspenseQuery(postalCodesQuery());
  const postalCodeValue = form.watch("postalCode");
  const [suggestionsVisible, setSuggestionsVisible] = React.useState(false);
  const suggestions =
    postalCodeValue.length > 0
      ? (postalCodes.data ?? []).filter((c) =>
          c.label.toLowerCase().includes(postalCodeValue.toLowerCase()),
        )
      : [];

  const selectSuggestion = (code: string) => {
    form.setValue("postalCode", code);
    setSuggestionsVisible(false);
  };

  return (
    <Context.Provider
      value={{
        setSuggestionsVisible,
        suggestionsVisible,
        suggestions,
        selectSuggestion,
      }}
    >
      {children}
    </Context.Provider>
  );
};

PostalCodeField.Suggestions = Suggestions;
PostalCodeField.Provider = Provider;

export { PostalCodeField };
