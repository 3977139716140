import { Trans } from "@lingui/react/macro";
import { useSidebar } from "@/common/providers/sidebar-provider";
import { FullscreenModalLayout } from "@/routes/layout";
import {
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Spacer } from "../spacer/spacer";
import Cross from "@icons/cross.svg?react";
import {
  archiveNotification,
  getNotifications,
} from "@/generated/api/notifications";
import {
  Notification as NotificationComponent,
  Props as NotificationProps,
} from "./notification";
import { DateTime } from "luxon";
import { Spinner } from "../spinner/spinner";
import Trash from "@icons/trash.svg?react";
import { toaster } from "../toast/toast";

const NotificationList = () => {
  const sidebar = useSidebar();
  const notifications = useSuspenseQuery(notificationsQuery());

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={() => sidebar.dispatch({ type: "toggle" })}>
          <Cross />
        </button>
      }
    >
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Notifikace a zprávy</Trans>
      </h1>
      <Spacer className="h-6" />
      <div className="flex flex-col gap-y-4">
        {notifications.data.map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))}
      </div>
      <Spacer className="h-6" />
      <p className="rounded-2xl bg-[#EFF5F4] p-4 text-center">
        <small>
          <Trans>
            Notifikace a zprávy mají životnost 30 dní. Následně jsou samy
            automaticky smazány z přehledu.
          </Trans>
        </small>
      </p>
      <Spacer className="h-6" />
    </FullscreenModalLayout>
  );
};

const Notification = ({
  createdAt,
  id,
  title,
  body,
}: { id: number } & NotificationProps) => {
  const queryClient = useQueryClient();
  const archiveMutation = useMutation({
    mutationFn: ({ id }: { id: number }) => archiveNotification(id),
    onError: () => {
      toaster.error(
        { title: <Trans>Notifikaci se nepodařilo smazat</Trans> },
        {
          autoClose: 5000,
        },
      );
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["notifications"],
        (previous: Awaited<ReturnType<typeof getNotifications>>) =>
          previous.filter((notification) => notification.id !== id),
      );
    },
  });

  return (
    <NotificationComponent key={id}>
      <NotificationComponent.Icon />
      <NotificationComponent.Content>
        <NotificationComponent.Title>{title}</NotificationComponent.Title>
        <NotificationComponent.Body>{body}</NotificationComponent.Body>
      </NotificationComponent.Content>
      <NotificationComponent.Button
        disabled={archiveMutation.status === "pending"}
        onClick={() => archiveMutation.mutate({ id })}
      >
        {archiveMutation.status === "pending" ? (
          <Spinner withHint={false} className="!h-4 !w-4" />
        ) : (
          <Trash />
        )}
      </NotificationComponent.Button>
      <NotificationComponent.CreatedAt>
        {DateTime.fromISO(createdAt)}
      </NotificationComponent.CreatedAt>
    </NotificationComponent>
  );
};

function notificationsQuery() {
  return queryOptions({
    queryKey: ["notifications"],
    queryFn: ({ signal }) => getNotifications({ signal }),
  });
}

export { NotificationList };
