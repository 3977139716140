import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { futureQuartersQuery } from "@/routes/trades/trades.api";
import { InputField } from "../form/input-field/input-field";
import React, { Suspense } from "react";
import { Spinner } from "../spinner/spinner";
import { ErrorBoundary } from "react-error-boundary";
import { Failed } from "../info/info";
import { companiesQuery } from "@/routes/storages/api";
import { getHarvestYearFromQuarter, getThisYear } from "@/common/utils/trading";
import { ErrorMessage } from "@hookform/error-message";

/*
type WithLowPrice = {
  hasLowPrice: true;
  lowPricePerTon: number;
};
type WithoutLowPrice = {
  hasLowPrice: false;
  lowPricePerTon?: never;
};
*/

type Fields = {
  quarter: string;
  pricePerTon: number;
  // FIXME `hasLowPrice` with `lowPricePerTon` should be a union type of WithLowPrice | WithoutLowPrice,
  // but somehow, `DefaultValues` of react-hook-form infers `hasLowPrice` as either true or false, not boolean
  hasLowPrice: boolean;
  lowPricePerTon: number | null;
  trader: string;
  tons: number;
  customTransport: boolean;
  customTransportNote: string | null;
  note?: string;
  harvestYear: number;
};

type FastFields = Fields & {
  company: string;
};

const OrderForm = <TFields extends FieldValues = Fields>({
  children,
  initialValues,
  onSubmit,
}: React.PropsWithChildren<{
  onSubmit: (data: TFields) => void;
  initialValues: DefaultValues<TFields>;
}>) => {
  const form = useForm<TFields>({
    defaultValues: initialValues,
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        id={id}
        className="flex flex-col gap-y-6"
      >
        <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </ErrorBoundary>
      </form>
    </FormProvider>
  );
};

const CompanyField = () => {
  const companies = useSuspenseQuery(companiesQuery());
  const form = useFormContext<Fields & { company: string }>();
  return (
    <label
      className={companies.data.length === 1 ? "hidden" : ""}
      htmlFor="company"
    >
      <Trans>Společnost</Trans>
      <select
        className="mt-2 w-full rounded-lg p-4"
        {...form.register("company")}
      >
        {companies.data.map((company) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </select>
    </label>
  );
};

const CustomTransportField = () => {
  const form = useFormContext<Fields>();
  const hasCustomTransport = form.watch("customTransport");

  React.useEffect(() => {
    if (!hasCustomTransport) {
      form.setValue("customTransportNote", null);
    }
  }, [form, hasCustomTransport]);

  return (
    <>
      <label
        className="relative flex h-6 justify-between"
        htmlFor="custom-transport"
      >
        <Trans>Vlastní doprava</Trans>
        <input
          {...form.register("customTransport")}
          type="checkbox"
          role="switch"
        />
      </label>
      {hasCustomTransport ? (
        <>
          <label htmlFor="custom-transport-note">
            <Trans>Poznámka k dopravě</Trans>
            <textarea
              className="mt-2 w-full rounded-lg"
              {...form.register("customTransportNote")}
            />
          </label>
        </>
      ) : null}
    </>
  );
};

const NoteField = () => {
  const form = useFormContext<Fields>();

  return (
    <label htmlFor="note">
      <Trans>Poznámka</Trans>
      <textarea
        rows={1}
        className="mt-2 w-full rounded-lg"
        {...form.register("note")}
        id="note"
      />
    </label>
  );
};

const AmountField = () => (
  <InputField<Fields>
    label={<Trans>Množství v tunách</Trans>}
    step="0.1"
    autoComplete="off"
    name="tons"
    type="number"
    options={{
      required: t`Vyplňte prosím množství`,
      valueAsNumber: true,
      min: {
        value: 1,
        message: t`Množství musí být vyšší než 1 tuna`,
      },
    }}
  />
);

const QuarterField = () => {
  const form = useFormContext<Fields>();
  const futureQuarters = useSuspenseQuery(futureQuartersQuery());
  const quarterId = form.watch("quarter");
  const quarter = futureQuarters.data.find((q) => q.id === quarterId);
  const harvestYear = quarter
    ? getHarvestYearFromQuarter(quarter.name)
    : getThisYear();

  React.useEffect(() => {
    form.setValue("harvestYear", harvestYear);
  }, [form, harvestYear]);

  return (
    <label htmlFor="quarter" className="group">
      <Trans>Kvartál</Trans>
      <select
        id="quarter"
        className="mt-2 h-[calc(theme(spacing.14)+2px)] w-full rounded-lg p-4 font-bold group-has-[[role=alert]]:border-can-russet"
        {...form.register("quarter", {
          required: t`Prosím vyplňte kvartál`,
        })}
      >
        {futureQuarters.data.map((quarter) => (
          <option key={quarter.id} value={quarter.id}>
            {quarter.name}
          </option>
        ))}
      </select>
      <ErrorMessage
        errors={form.formState.errors}
        name="quarter"
        render={({ message }) => (
          <p
            role="alert"
            className="mt-2 basis-full text-left text-xs text-can-russet"
          >
            {message}
          </p>
        )}
      />
    </label>
  );
};

const TraderField = () => {
  const form = useFormContext<Fields>();
  return (
    <label htmlFor="trader">
      <Trans>Obchodník</Trans>
      <select
        required
        id="trader"
        className="mt-2 w-full rounded-lg p-4"
        {...form.register("trader")}
      >
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        <option value="1">TOP EKOS</option>
      </select>
    </label>
  );
};

const LowPriceField = ({ currency }: { currency: "CZK" | "EUR" }) => {
  const form = useFormContext<Fields>();
  const hasLowPrice = form.watch("hasLowPrice");

  React.useEffect(() => {
    if (!hasLowPrice) {
      form.setValue("lowPricePerTon", null);
    }
  }, [hasLowPrice, form]);

  return (
    <>
      <div>
        <label
          className="relative flex h-6 justify-between"
          htmlFor="custom-transport"
        >
          <Trans>Zahrnout pokles ceny</Trans>
          <input
            {...form.register("hasLowPrice")}
            type="checkbox"
            role="switch"
          />
        </label>
        <small>
          <Trans>
            Pohlídejte si i případný pokles ceny a nastavte si minimální částku,
            za kterou dojde k prodeji komodity.
          </Trans>
        </small>
      </div>
      {hasLowPrice ? (
        <InputField<Fields>
          step="0.01"
          type="number"
          name="lowPricePerTon"
          label={<Trans>Minimální cena za tunu ({currency})</Trans>}
          options={{
            validate: () => {
              const lowPrice = form.getValues("lowPricePerTon");
              const price = form.getValues("pricePerTon");
              const hasLowPrice = form.getValues("hasLowPrice");
              if (!hasLowPrice) {
                return true;
              }

              if (lowPrice === null) {
                return;
              }

              return (
                lowPrice <= price ||
                t`Minimální cena musí být nižší než cena za tunu`
              );
            },
            required: t`Vyplňte prosím cenu`,
            valueAsNumber: true,
            min: {
              value: 1,
              message: t`Cena musí být vyšší než 0`,
            },
          }}
        />
      ) : null}
    </>
  );
};

const PriceField = ({ currency }: { currency: "CZK" | "EUR" }) => (
  <InputField<Fields>
    step="0.01"
    type="number"
    name="pricePerTon"
    label={<Trans>Cena za tunu ({currency})</Trans>}
    options={{
      required: t`Vyplňte prosím cenu`,
      valueAsNumber: true,
      min: {
        value: 1,
        message: t`Cena musí být vyšší než 0`,
      },
    }}
  />
);
const id = "order-form";

OrderForm.formId = "order-form";
OrderForm.AmountField = AmountField;
OrderForm.CustomTransportField = CustomTransportField;
OrderForm.LowPriceField = LowPriceField;
OrderForm.NoteField = NoteField;
OrderForm.QuarterField = QuarterField;
OrderForm.PriceField = PriceField;
OrderForm.TraderField = TraderField;
OrderForm.CompanyField = CompanyField;

export { OrderForm };
export type { Fields, FastFields };
