import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useSidebar } from "@/common/providers/sidebar-provider";
import { FullscreenModalLayout } from "@/routes/layout";
import Cross from "@icons/cross.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { useAuth0 } from "@auth0/auth0-react";
import invariant from "tiny-invariant";
import { getUser } from "@/common/services/user";
import { FormProvider, useForm } from "react-hook-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import { languagesQuery } from "@/routes/admin/editor/editor.api";
import { Button } from "../button/button";
import {
  appLanguagesQuery,
  useUpdateUserSettingsMutation,
} from "./user-settings.api";
import { fallbackLanguage } from "@/common/services/i18n";
import { Success } from "@/routes/shared/routes/success/success";
import { Failed } from "@/routes/shared/routes/failed/failed";
import Building from "@icons/building.svg?url";
import { PhoneNumber } from "../form/phone-number/phone-number";
import { InputField } from "../form/input-field/input-field";
import { useUpdateToken } from "@/common/hooks/use-update-token";
import { useCountryCode } from "./hooks/use-country-code";
import { CompanyEditGuard } from "@/common/acl/statutory-guard/company-edit-guard";
import { CompanyForm } from "./components/company-form/company-form";

type Fields = {
  username: string;
  email: string;
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  newsLanguage: string;
  language: string;
};

const UserSettings = () => {
  const sidebar = useSidebar();
  const [isTokenUpdating, updateToken] = useUpdateToken();
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getUser(_user);
  const appLanguage =
    user.user_metadata?.can?.settings?.language ?? fallbackLanguage;
  const appLanguages = useSuspenseQuery(appLanguagesQuery());
  const marketNewsLanguages = useSuspenseQuery(languagesQuery());
  const { current: countryCode, phoneCountryCodes } =
    useCountryCode(appLanguage);
  const updateUser = useUpdateUserSettingsMutation();
  const form = useForm<Fields>({
    defaultValues: {
      language: appLanguage,
      newsLanguage:
        user.user_metadata.can.settings?.news?.language ?? fallbackLanguage,
      username: user.name,
      email: user.email,
      phone: {
        phoneNumber: user.user_metadata.common.phone.phoneNumber,
        countryCode: countryCode,
      },
    },
  });

  const handleSubmit = (data: Fields) => {
    updateUser.mutate(
      {
        phone: {
          phoneNumber: data.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(data.phone.countryCode)[0],
        },
        name: data.username,
        newsLanguage: data.newsLanguage,
        language: data.language,
      },
      {
        onSuccess: async () => {
          await updateToken();
          sidebar.dispatch({ type: "open", screen: "user-success" });
        },
        onError: () => {
          sidebar.dispatch({ type: "open", screen: "user-failure" });
        },
      },
    );
  };

  if (sidebar.state.screen === "user-failure") {
    return (
      <Failed
        headerButton={
          <button onClick={() => sidebar.dispatch(sidebar.state.closeAction)}>
            <Cross />
          </button>
        }
        title={<Trans>Údaje se nepodařilo uložit</Trans>}
        content={
          <Trans>
            Bohužel někde nastala chyba a údaje se nepodařilo uložit. Zkuste to
            později.
          </Trans>
        }
        action={
          <Button
            onClick={() => sidebar.dispatch(sidebar.state.nextAction)}
            variant="primary"
          >
            <Trans>Přejít na profil</Trans>
          </Button>
        }
      />
    );
  }

  if (sidebar.state.screen === "user-success") {
    return (
      <Success
        headerButton={
          <button onClick={() => sidebar.dispatch(sidebar.state.closeAction)}>
            <Cross />
          </button>
        }
        title={
          <Success.Title>
            <Trans>Údaje úspěšně uloženy</Trans>
          </Success.Title>
        }
        content={
          <Success.Content>
            <Trans>
              Údaje jsme úspěšně uložili. Více informací najdete ve vašem
              profilu.
            </Trans>
          </Success.Content>
        }
        action={
          <Button
            onClick={() => sidebar.dispatch(sidebar.state.nextAction)}
            variant="primary"
          >
            <Trans>Přejít na profil</Trans>
          </Button>
        }
      />
    );
  }

  if (sidebar.state.screen === "user-company") {
    return (
      <CompanyEditGuard>
        <CompanyForm />
      </CompanyEditGuard>
    );
  }

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={() => sidebar.dispatch({ type: "toggle" })}>
          <Cross />
        </button>
      }
    >
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Profil</Trans>
      </h1>
      <Spacer className="h-6" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          id="user-settings-form"
          className="flex flex-col gap-y-6"
        >
          <InputField<Fields>
            name="username"
            label={<Trans>Jméno a příjmení</Trans>}
            options={{
              required: t`Vyplňte prosím jméno a příjmení`,
            }}
          />

          <InputField<Fields>
            name="email"
            label={<Trans>E-mail</Trans>}
            disabled
          />

          <PhoneNumber name="phone" callingCodes={phoneCountryCodes.data} />

          <label className="group" htmlFor="app-language">
            <Trans>Jazyk aplikace</Trans>
            <select
              required
              id="app-language"
              className="mt-2 block w-full rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet"
              {...form.register("language")}
            >
              {appLanguages.data.map((language) => (
                <option key={language.id} value={language.id}>
                  {language.localName}
                </option>
              ))}
            </select>
          </label>
          <h2 className="font-bold text-can-forest-teal">
            <Trans>Market news</Trans>
          </h2>
          <p>
            <Trans>
              Nastavte si jazyk, ve kterém chcete dostávat týdenní informace o
              událostech na trhu.
            </Trans>
          </p>

          <label className="group" htmlFor="market-news">
            <Trans>Jazyk aktualit</Trans>
            <select
              required
              id="market-news"
              className="mt-2 block w-full rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet"
              {...form.register("newsLanguage")}
            >
              {marketNewsLanguages.data.map((language) => (
                <option key={language.id} value={language.id}>
                  {language.localName}
                </option>
              ))}
            </select>
          </label>
        </form>
      </FormProvider>
      <Button.Container>
        {(user.user_metadata.can.companies?.length ?? 0) > 0 ? (
          <Button
            onClick={() => sidebar.dispatch(sidebar.state.nextAction)}
            variant="secondary"
            style={{
              // eslint-disable-next-line lingui/no-unlocalized-strings
              background: `url(${Building}) left 16px center no-repeat`,
            }}
          >
            <Trans>Informace o společnosti</Trans>
          </Button>
        ) : null}
        <Button
          type="submit"
          disabled={updateUser.status === "pending" || isTokenUpdating}
          form="user-settings-form"
          variant="primary"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </FullscreenModalLayout>
  );
};

export { UserSettings };
