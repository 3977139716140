import { Trans } from "@lingui/react/macro";
import React from "react";
import { Button } from "@/common/components/button/button";

const CropDetail = ({
  children,
  onSubmit,
  onDelete,
  tons = 0,
}: React.PropsWithChildren<{
  onDelete?: () => void;
  onSubmit: (tons: number) => void;
  tons: number;
}>) => {
  const [cropTons, setCropTons] = React.useState(tons);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(cropTons);
      }}
      className="flex grow flex-col gap-y-4"
    >
      {children}
      <label htmlFor="name">
        <Trans>Skladem (tun)</Trans>
        <input
          type="number"
          min={1}
          value={cropTons}
          onChange={(e) => setCropTons(e.target.valueAsNumber)}
          autoComplete="off"
          required
          className="mt-2 block w-full rounded-lg border p-4 font-bold"
          id="name"
        />
      </label>
      <Button.Container>
        {onDelete ? (
          <Button.Delete onClick={onDelete}>
            <Trans>Smazat komoditu</Trans>
          </Button.Delete>
        ) : null}

        <Button variant="primary" type="submit">
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </form>
  );
};

export { CropDetail };
