import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import AngleLeft from "@icons/angle-left.svg?react";
import { PageTitle } from "@/common/components/page-title/page-title";
import { Spacer } from "@/common/components/spacer/spacer";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { storageQuery } from "../shared/api/get-storage";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { cropsQuery, useAddCrop } from "./api";
import { Crop, CropList } from "./components/crop-list/crop-list";
import { Info } from "@/common/components/info/info";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { InputField } from "@/common/components/form/input-field/input-field";
import { Button } from "@/common/components/button/button";
import { getHarvestYears } from "@/common/utils/trading";
import { ErrorMessage } from "@hookform/error-message";

const path = "crops/new";

const NewCrop = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "Storage id is not defined");
  const queryClient = useQueryClient();
  const storage = useSuspenseQuery(storageQuery(id));
  const { currentCrop, handleCropSelect, handleCropClear } = useCurrentCrop();
  const availableCrops = useSuspenseQuery(cropsQuery());
  const addCropMutation = useAddCrop();
  const navigate = useNavigate();

  const handleSubmit = (data: Fields) => {
    invariant(currentCrop);
    addCropMutation.mutate(
      {
        harvestYear: data.harvestYear,
        storageId: id,
        storage: {
          zip: storage.data.postalCode,
          crops: {
            id: currentCrop.id,
            tons: data.tons,
          },
          label: storage.data.name,
          companyId: storage.data.company.id,
          address: storage.data.address,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
          navigate("../..", {
            relative: "path",
          });
        },
      },
    );
  };

  return (
    <FullscreenModalLayout
      headerButton={
        currentCrop ? (
          <button onClick={handleCropClear}>
            <AngleLeft />
          </button>
        ) : (
          <SearchParamsLink relative="path" to="../.." replace>
            <AngleLeft />
          </SearchParamsLink>
        )
      }
    >
      <Spacer className="h-6" />
      <PageTitle>
        <Trans>Přidat komoditu</Trans>
      </PageTitle>
      <OfferPropertiesList
        properties={[
          {
            label: <Trans>Adresa</Trans>,
            value: storage.data.address,
          },
          {
            label: <Trans>Farma</Trans>,
            value: storage.data.company.name,
          },
          {
            label: <Trans>Sklad</Trans>,
            value: storage.data.name,
          },
        ]}
      />
      <Spacer className="h-6" />
      {currentCrop ? (
        <CropForm
          onSubmit={
            addCropMutation.status === "pending" ? () => {} : handleSubmit
          }
        />
      ) : availableCrops.data.length > 0 ? (
        <CropList onClick={handleCropSelect} crops={availableCrops.data} />
      ) : (
        <Info>
          <Trans>Ve skladu jsou již všechny dostupné komodity</Trans>
        </Info>
      )}
    </FullscreenModalLayout>
  );
};

type Fields = {
  tons: number;
  harvestYear: number;
};

const CropForm = ({ onSubmit }: { onSubmit: (data: Fields) => void }) => {
  const form = useForm<Fields>();

  return (
    <FormProvider {...form}>
      <form id="crop-form" onSubmit={form.handleSubmit(onSubmit)}>
        <InputField<Fields>
          step="0.1"
          type="number"
          label={<Trans>Skladem tun</Trans>}
          name="tons"
          options={{
            required: t`Vyplňte prosím množství`,
            valueAsNumber: true,
            min: {
              value: 1,
              message: t`Množství musí být vyšší než 1 tuna`,
            },
          }}
        />
        <Spacer className="h-6" />
        <label htmlFor="harvestYear" className="group">
          <Trans>Rok sklizně</Trans>
          <select
            defaultValue=""
            className="mt-2 h-[calc(theme(spacing.14)+2px)] w-full rounded-lg p-4 font-bold group-has-[[role=alert]]:border-can-russet"
            {...form.register("harvestYear", {
              valueAsNumber: true,
              required: t`Vyplňte prosím rok sklizně`,
            })}
          >
            <option value="" disabled></option>
            {getHarvestYears().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={form.formState.errors}
            name="harvestYear"
            render={({ message }) => (
              <p
                role="alert"
                className="mt-2 basis-full text-left text-xs text-can-russet"
              >
                {message}
              </p>
            )}
          />
        </label>
      </form>
      <Button.Container>
        <Button variant="primary" form="crop-form">
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </FormProvider>
  );
};

const useCurrentCrop = () => {
  const [currentCrop, setCurrentCrop] = React.useState<Crop>();

  const handleCropSelect = (crop: Crop) => {
    setCurrentCrop(crop);
  };

  const handleCropClear = () => {
    setCurrentCrop(undefined);
  };

  return { handleCropSelect, handleCropClear, currentCrop };
};

export { NewCrop, path };
