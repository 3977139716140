import { Trans } from "@lingui/react/macro";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import AngleLeft from "@icons/angle-left.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  confirmationQuery,
  ConfirmationQueryResult,
  contractQuery,
  ContractQueryResult,
} from "./contracts.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { DocusealForm } from "@docuseal/react";
import { useAuth0 } from "@auth0/auth0-react";
import { getUser } from "@/common/services/user";
import { useToggle } from "@/common/hooks/use-toggle";
import { Spinner } from "@/common/components/spinner/spinner";
import { TooManyCompanies } from "@/common/components/too-many-companies/too-many-companies";
import { Info } from "@/common/components/info/info";
import { Button, secondary } from "@/common/components/button/button";
import Download from "@icons/download.svg?url";
import { CanISignContractGuard } from "@/common/acl/statutory-guard/contract-sign-guard";
import { ContractStatus } from "@/generated/digitalnisklady.cz/graphql";
import { Failed } from "../shared/routes/failed/failed";

const path = "signature";

const Signature = () => {
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getUser(_user);
  const { contractId } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(contractId, "contractId param is not defined");
  const contract = useSuspenseQuery(contractQuery({ id: contractId }));
  const confirmation = useSuspenseQuery(confirmationQuery({ contractId }));

  return (
    <CanISignContractGuard>
      <CanContractBeSigned status={contract.data.status}>
        <FullscreenModalLayout
          headerButton={
            <SearchParamsLink to="..">
              <AngleLeft />
            </SearchParamsLink>
          }
        >
          <Spacer className="h-6" />
          <h1 className="text-2xl font-black text-can-forest-teal">
            <Trans>Podepsat smlouvu</Trans>
          </h1>
          <TooManyCompanies
            companies={user["can/app_metadata"]?.companies ?? undefined}
          >
            <ContractStatusComponent
              confirmation={confirmation.data}
              contract={contract.data}
            />
          </TooManyCompanies>
        </FullscreenModalLayout>
      </CanContractBeSigned>
    </CanISignContractGuard>
  );
};

const ContractStatusComponent = ({
  contract,
  confirmation,
}: {
  contract: ContractQueryResult;
  confirmation: ConfirmationQueryResult;
}) => {
  if (
    contract.status === ContractStatus.Confirmed &&
    !confirmation?.signedByTrader
  ) {
    return (
      <Info>
        <Trans>
          Smlouva byla schválena a čeká na podpis obchodníka. Zkuste akci
          opakovat za chvíli.
        </Trans>
      </Info>
    );
  }

  if (!confirmation || !confirmation.signedByTrader) {
    return (
      <Info>
        <Trans>
          Smlouvu nyní nelze podepsat, čeká na schválení protistrany.
        </Trans>
      </Info>
    );
  }

  if (confirmation.confirmation?.completed) {
    return (
      <>
        <Info>
          <Trans>Smlouva již byla podepsána</Trans>
        </Info>
        <Button.Container>
          <a
            target="_blank"
            className={secondary}
            href={confirmation.confirmation.documents.at(0)?.url}
            style={{
              background: `url(${Download}) left 16px center no-repeat`,
            }}
            rel="noreferrer"
          >
            <Trans>Stáhnout smlouvu v PDF</Trans>
          </a>
        </Button.Container>
      </>
    );
  }

  if (confirmation.docusealSlug) {
    return <Form slug={confirmation.docusealSlug} />;
  }
};

const Form = ({ slug }: { slug: string }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const callbackUrl =
    location.pathname.replace("signature", "success") + "?" + location.search;
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getUser(_user);
  const formReady = useToggle();

  const handleComplete = () => {
    void queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("confirmation"),
    });
    navigate(callbackUrl);
  };

  return (
    <>
      {formReady.on ? null : <Spinner withHint={false} className="mt-2" />}
      <DocusealForm
        email={user.email}
        onLoad={formReady.toggle}
        src={`https://docuseal.eu/s/${slug}`}
        language={user.user_metadata.can.settings?.language}
        withTitle={false}
        backgroundColor="#FFFFFF"
        withDownloadButton={false}
        withSendCopyButton={false}
        allowToResubmit={false}
        onComplete={handleComplete}
        rememberSignature
        // eslint-disable-next-line lingui/no-unlocalized-strings
        customCss={`
          * {
            font-family: Roboto
          }

          div.field-area {
            font-size: 6pt !important;
          }

          #submit_form_button {
            border: none;
            border-radius: 16px;
            color: #FFFFFF;
            font-weight: bold;
            background: var(--gradient, linear-gradient(80deg, #60A6D3 0%, #58B4A1 97.67%));
            box-shadow: 2px 4px 12px 0px rgba(122, 128, 147, 0.25);
          }

          input.base-input {
            border-radius: 8px;
            color: #616161;
            font-size: 1rem !important;
          }

          input.base-input:focus {
            outline-style: none
          }

          button#minimize_form_button {
            display: none
          }

          form#form_container {
            padding-top: 0;
          }

          div.tooltip {
            display: none;
          }

          div#form_container label.label {
            color: #616161;
            font-size: 1rem;
            margin: 0;
          }

          a.btn-ghost {
            color: #616161
          }

          a.btn-ghost:hover {
            background-color: initial;
          }

          a#type_text_button,
          a.btn-outline,
          label.btn-outline {
            border-radius: 8px;
            color: #168B6B;
          }

          a#type_text_button:hover,
          a.btn-outline:hover,
          label.btn-outline:hover {
            background-color: #FFFFFF;
            border-color: #168B6B;
            color: #168B6B;
          }

          .tooltip:after,
          .tooltip:before {
            display: none
          }

          div.space-x-2:has(> span:nth-of-type(2)) {
            overflow-x: auto
          }
        `}
      />
    </>
  );
};

const CanContractBeSigned = ({
  children,
  status,
}: React.PropsWithChildren<{ status: ContractStatus }>) => {
  switch (status) {
    case ContractStatus.Pending:
      return (
        <Failed
          title=""
          content={
            <Trans>
              Smlouvu nyní nelze podepsat, čeká na schválení a podpis
              obchodníka.
            </Trans>
          }
        />
      );
    case ContractStatus.Processing:
      return (
        <Failed
          title=""
          content={
            <Trans>
              Smlouvu nyní nelze podepsat, čeká na schválení a podpis
              obchodníka.
            </Trans>
          }
        />
      );
    case ContractStatus.Signed:
    case ContractStatus.Confirmed:
    case ContractStatus.Success:
    case ContractStatus.Failed:
      return <>{children}</>;
    default: {
      const exhaustiveCheck: never = status;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
    }
  }
};

export { Signature, path };
