import { z } from "zod";

type EnvironmentSchema = z.infer<typeof environmentSchema>;
type RemoveVitePrefix<T> = {
  [K in keyof T as K extends `VITE_${infer U}` ? U : K]: T[K];
};
type AppModeSchema = z.infer<typeof appMode>;

const appMode = z.union([
  z.literal("local"),
  z.literal("nightly"),
  z.literal("staging"),
  z.literal("production"),
]);

const environmentSchema = z.object({
  VITE_APP_MODE: appMode,
  VITE_AUTH0_AUDIENCE: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_DIGITALNI_SKLADY_API_URL: z.string().startsWith("https://"),
  VITE_FIREBASE_API_KEY: z.string(),
  VITE_FIREBASE_APP_ID: z.string(),
  VITE_FIREBASE_AUTH_DOMAIN: z.string(),
  VITE_FIREBASE_MEASUREMENT_ID: z.string(),
  VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
  VITE_FIREBASE_PROJECT_ID: z.string(),
  VITE_FIREBASE_STORAGE_BUCKET: z.string(),
  VITE_FIREBASE_VAPID_KEY: z.string(),
  VITE_GOOGLE_ANALYTICS_ID: z.string().optional(),
  VITE_NEWS_API_URL: z.string().startsWith("https://"),
  VITE_NEWS_OPENAPI_URL: z.string().startsWith("https://"),
  VITE_NOTIFICATIONS_API_URL: z.string().startsWith("https://"),
  VITE_NOTIFICATIONS_OPENAPI_URL: z.string().startsWith("https://"),
  VITE_PRICES_API_URL: z.string().startsWith("https://"),
  VITE_PRICES_OPENAPI_URL: z.string().startsWith("https://"),
  VITE_PRODUCT_FRUITS_WORKSPACE_CODE: z.string(),
  VITE_RELEASE_ID: z.string().or(z.undefined()), // only set on production
  VITE_USERS_API_URL: z.string().startsWith("https://"),
  VITE_USERS_OPENAPI_URL: z.string().startsWith("https://"),
});

const envLike = import.meta.env ?? process.env;
const safeEnvironment = environmentSchema.safeParse(envLike);

if (!safeEnvironment.success) {
  process.stdout.write(
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "Error: the app environment is not in tact. Check env.ts file.\n",
  );
  safeEnvironment.error.issues.map((issue) =>
    process.stdout.write(JSON.stringify(issue) + "\n"),
  );
  process.exit(1);
}

const getEnvironmentVariable = <
  K extends keyof RemoveVitePrefix<EnvironmentSchema>,
>(
  key: K,
) => safeEnvironment.success && safeEnvironment.data[`VITE_${key}`];

export { getEnvironmentVariable };
export type { AppModeSchema };
