import { Features } from "@/common/providers/feature-provider";
import { PlanSchema } from "@/common/services/user";

type FeatureMode = "trial" | "full" | "not-available";

const getFeatureMode = (
  companyPlan: PlanSchema["planId"],
  feature: Features[keyof Features],
): FeatureMode => {
  if (!feature) {
    return "not-available";
  }

  if (companyPlan !== feature.planId) {
    return "trial";
  }

  return "full";
};

export { getFeatureMode };
export type { FeatureMode };
