import Diamond from "@icons/diamond.svg?react";
import Ellipse from "@icons/ellipse.svg?react";

const PremiumBadge = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <span
        data-testid="badge"
        className="absolute left-4 top-2 grid place-content-center place-items-center [&_path]:fill-[#ffffff]"
      >
        <Ellipse style={{ gridRow: 1, gridColumn: 1 }} className="h-4 w-4" />
        <Diamond style={{ gridRow: 1, gridColumn: 1 }} />
      </span>
      {children}
    </>
  );
};

export { PremiumBadge };
