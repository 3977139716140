import { isNull } from "lodash";
import React from "react";

const defaultEmptyCheckFn: (input: string | null) => input is null = isNull;

const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  emptyCheckFn = defaultEmptyCheckFn,
) => {
  const [state, setState] = React.useState<T>(() => {
    const current = getLocalStorageItem(key);

    if (!emptyCheckFn(current)) {
      try {
        return JSON.parse(current);
      } catch {
        removeLocalStorageItem(key);
      }
    }

    return initialValue;
  });

  React.useEffect(() => {
    if (state === null || state === undefined) {
      removeLocalStorageItem(key);
    } else {
      setLocalStorageItem(key, state);
    }
  }, [key, state]);

  return [state, setState] as const;
};

const setLocalStorageItem = (key: string, value: unknown) => {
  const stringified = JSON.stringify(value);
  window.localStorage.setItem(key, stringified);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
};

const getLocalStorageItem = (key: string) => window.localStorage.getItem(key);

const arrayEmptyCheckFn = (input: string | null): input is null =>
  isNull(input) || input === "[]";

export { useLocalStorage, arrayEmptyCheckFn };
