import { msg } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Metadata } from "@/common/components/metadata/metadata";
import { useCurrency } from "@/common/hooks/use-currency";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { storagesQuery } from "./trades.api";
import { TwoColumnLayout } from "../layout";
import { Spinner } from "@/common/components/spinner/spinner";
import { Failed } from "@/common/components/info/info";
import { useCurrencyParams, initialParams } from "../shared/currency.params";
import { useIsMobile } from "@/common/hooks/use-media-query";
import GarageOpenSvg from "@icons/garage-open.svg?react";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { CommodityCard } from "../storages/components/commodity-card/commodity-card";
import AngleSmallDownSvg from "@icons/angle-small-down.svg?react";
import { PriceList } from "@/common/components/price-list/price-list";
import { PageTitle } from "@/common/components/page-title/page-title";
import { useLingui } from "@lingui/react";
import { useSidebar } from "@/common/providers/sidebar-provider";
import {
  SearchField,
  useSearchFieldParams,
} from "@/common/components/search-field/search-field";
import { getPricesView } from "../purchase-prices/purchase-prices";

const path = "trades";

const Trades = () => {
  const { _ } = useLingui();
  const [search] = useSearchFieldParams();
  const { offerId } = useParams<"offerId">();
  const [CurrencySwitch, currency] = useCurrency();
  const storages = useQuery(
    storagesQuery({
      currency,
      search,
    }),
  );
  const isMobile = useIsMobile();
  const isDetailOpen = offerId !== undefined;
  const sidebar = useSidebar();

  return (
    <TwoColumnLayout
      left={{
        header: (
          <>
            <PageTitle>
              <Trans>Komodity k prodeji</Trans>
            </PageTitle>
            {CurrencySwitch}
          </>
        ),
        content: (
          <>
            <Metadata title={_(msg`Komodity k prodeji`)} />
            {storages.status === "pending" ? (
              <div className="flex basis-full items-center justify-center">
                <Spinner />
              </div>
            ) : null}
            {storages.status === "error" ? (
              <Failed error={storages.error} />
            ) : null}
            {storages.status === "success" && storages.data ? <></> : null}
            {storages.status === "success" && storages.data ? (
              <>
                <SearchField />
                {storages.data.map(({ company }) => (
                  <section className="flex flex-col gap-y-4" key={company.id}>
                    <h2 className="text-xl font-bold text-can-midnight-steel">
                      {company.name}
                    </h2>
                    <ul className="flex flex-col gap-y-6">
                      {company.storages.map((storage) => (
                        <li key={storage.id} className="last-of-type:mb-8">
                          <details open={!isMobile} className="group">
                            <summary className="flex list-none flex-col">
                              <h3 className="flex items-center gap-x-2 font-bold text-can-forest-teal [&_path]:fill-[currentColor]">
                                <GarageOpenSvg className="h-4 w-4" />
                                {storage.mark ? (
                                  <mark className="text-can-slate-blue-gray">
                                    {storage.name}
                                  </mark>
                                ) : (
                                  storage.name
                                )}
                                <AngleSmallDownSvg className="ml-auto rotate-180 group-open:rotate-0" />
                              </h3>
                            </summary>
                            <ul
                              className={`mt-6 grid grid-cols-1 gap-6  ${isDetailOpen || sidebar.state.screen ? "xl:grid-cols-2" : "md:grid-cols-2 2xl:grid-cols-3"}`}
                            >
                              {storage.offers.map((offer) => (
                                <li
                                  key={offer.id}
                                  className={
                                    offerId === offer.id
                                      ? "[&_article]:bg-can-silver-gray [&_h4]:text-can-forest-teal [&_path]:fill-can-forest-teal"
                                      : ""
                                  }
                                >
                                  <SearchParamsLink
                                    to={`${storage.id}/offers/${offer.id}`}
                                  >
                                    <CommodityCard>
                                      <CommodityCard.Title
                                        crop={{
                                          harvestYear: offer.harvestYear,
                                          name: offer.crop.name,
                                          mark: offer.crop.mark,
                                        }}
                                      />
                                      <div className="flex flex-wrap gap-1">
                                        <CommodityCard.Stored
                                          tons={offer.amount}
                                        />
                                        |
                                        <CommodityCard.Price
                                          tons={offer.amount}
                                          currency={currency}
                                          pricePerTon={
                                            offer.prices.at(0)?.value
                                          }
                                        />
                                      </div>
                                      {getPricesView(offer.prices).map(
                                        (prices, i) => (
                                          <PriceList
                                            prices={prices.map((p) =>
                                              p?.type === "data"
                                                ? {
                                                    id: p.id,
                                                    quarter: p.quarter.name,
                                                    value: p.price,
                                                    currency,
                                                  }
                                                : null,
                                            )}
                                            key={i}
                                          />
                                        ),
                                      )}
                                    </CommodityCard>
                                  </SearchParamsLink>
                                </li>
                              ))}
                            </ul>
                          </details>
                        </li>
                      ))}
                    </ul>
                  </section>
                ))}
              </>
            ) : null}
          </>
        ),
      }}
      right={isDetailOpen}
    />
  );
};

const TradesParams = ({ children }: React.PropsWithChildren) => {
  const { params } = useCurrencyParams();
  const { pathname } = useLocation();

  if (!params) {
    return <Navigate to={`${pathname}?${initialParams}`} />;
  }

  return <>{children}</>;
};

export { Trades, TradesParams, path };
