import { Trans } from "@lingui/react/macro";
import { Column } from "@tanstack/react-table";
import invariant from "tiny-invariant";

const Filter = <T,>({
  column,
  onFilterChange,
  value,
}: {
  column: Column<T, unknown>;
  onFilterChange: (key: string, value: string) => void;
  value: string;
}) => {
  const { filterKey } = column.columnDef.meta ?? {};
  invariant(
    filterKey,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `<Filter /> filterKey is not set for column: ${column.columnDef.id}`,
  );

  return (
    <input
      type="text"
      className="mt-2 rounded-lg border md:max-w-[150px]"
      value={(value ?? "") as string}
      onChange={(e) => onFilterChange(filterKey!, e.target.value)}
    />
  );
};

const SelectFilter = <T,>({
  column,
  onFilterChange,
  value,
  values,
}: {
  column: Column<T, unknown>;
  onFilterChange: (key: string, value: string) => void;
  value: string;
  values: Array<{ label: string; value: string }>;
}) => {
  const { filterKey } = column.columnDef.meta ?? {};
  invariant(
    filterKey,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `<SelectFilter /> filterKey is not set for column: ${column.columnDef.id}`,
  );
  return (
    <select
      className="mt-2 rounded-lg border md:max-w-[150px]"
      onChange={(e) => onFilterChange(filterKey!, e.target.value)}
      value={value ?? ""}
    >
      <option value="">
        <Trans>Vše</Trans>
      </option>
      {values.toSorted().map((value) => (
        <option value={value.value} key={value.value}>
          {value.label}
        </option>
      ))}
    </select>
  );
};

export { Filter, SelectFilter };
