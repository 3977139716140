import { Interval, DateTime } from "luxon";
import { Strategy } from "../reports.flow";
import { faker } from "@faker-js/faker";

class TrialStrategy implements Strategy {
  public isMasked(interval: Interval, date: DateTime) {
    return interval.contains(date);
  }

  public getDummyReport(): ReturnType<Strategy["getDummyReport"]> {
    return {
      commodities: Array.from({ length: 4 }).map(() => ({
        summary: faker.lorem.paragraph(),
        headline: faker.lorem.sentence(),
        commodity: {
          id: faker.number.int(),
          icon: "",
          name: faker.lorem.word(),
        },
        recommendation: "BULLISH",
        recommendationText: faker.lorem.word(),
      })),
    };
  }
}

export { TrialStrategy };
