import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  CompanyUser,
  companyPlansQuery,
  companyQuery,
  companyUsersQuery,
  useUpdateCompanySubscriptionPlanMutation,
} from "./company.api";
import { Link, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import Cross from "@icons/cross.svg?react";
import { countryCodeToFlag } from "@/common/utils/unicode";
import { Metadata } from "@/common/components/metadata/metadata";
import { Table } from "../components/table/table";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import {
  CompanyForm,
  Fields,
  getInitialValues,
} from "@/common/components/company-form/company-form";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import { Spacer } from "@/common/components/spacer/spacer";
import { useUpdateCompanyMutation } from "@/common/components/user-settings/components/company-form/company-form.api";
import { Button } from "@/common/components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { InputField } from "@/common/components/form/input-field/input-field";
import { useHasPermission } from "@/common/acl/guard/guard";
import { UserStateAction } from "../components/user-actions/user-state-action";
import { UserRoleAction } from "../components/user-actions/user-role-action";
import { resetForm } from "@/api/form-reset";
import { UserIdentity } from "@/common/components/user-identity/user-identity";
import { UserCompanyAction } from "../components/user-actions/user-company-action";

const path = "company/:id";
const Company = ({ children }: React.PropsWithChildren) => {
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));

  return (
    <>
      <Metadata title={company.data.name} />
      <Link className="sm:hidden" to="..">
        <Cross />
      </Link>
      <h1 className="text-xl font-bold">
        {company.data.name} {countryCodeToFlag(company.data.country)}
      </h1>
      <ul className="flex flex-col gap-y-2">
        <li>
          <Trans context="subscription">Předplatné</Trans>
          {": "}
          {company.data.plan?.type} ({company.data.planSource})
        </li>
        <li>
          <Trans>Konec předplatného</Trans>
          {": "}
          {company.data.planExpiresAt?.toLocaleString(DateTime.DATE_FULL)}
        </li>
      </ul>
      <hr />
      {children}
    </>
  );
};

const columnHelper = createColumnHelper<CompanyUser>();
const Users = () => {
  const id = useCompanyId();
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const hasUserCompanyDeletePermission = useHasPermission(
    "users:company:delete",
  );
  const hasCompanyRolePermission = useHasPermission("users:company:role");
  const users = useSuspenseQuery(companyUsersQuery({ id: parseInt(id) }));
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {}),
      columnHelper.accessor("userId", {}),
      columnHelper.accessor("role", {}),
      columnHelper.accessor("userEmail", {
        header: () => t`E-mail`,
        cell: (info) => (
          <Link
            className="flex gap-2 text-can-forest-teal"
            to={`../../users/${info.row.getValue("userId")}`}
          >
            <UserIdentity identity={info.row.getValue("userId")} />
            {info.getValue()}
          </Link>
        ),
        meta: {
          label: () => t`E-mail`,
        },
      }),
      columnHelper.accessor("approvalState", {
        header: () => t`Stav`,
        meta: {
          label: () => t`Stav`,
        },
      }),

      columnHelper.display({
        id: "state-action",
        header: () => t`Stav`,
        cell: hasCompanyApprovePermission
          ? ({ row }) => (
              <UserStateAction
                initialStatus={row.getValue("approvalState")}
                userCompanyRelationId={row.getValue("id")}
              />
            )
          : ({ row }) => row.getValue("approvalState"),
      }),
      columnHelper.display({
        id: "role-action",
        header: () => t`Role`,
        cell: hasCompanyRolePermission
          ? ({ row }) => (
              <UserRoleAction
                userCompanyRelationId={row.getValue("id")}
                initialRole={row.getValue("role")}
              />
            )
          : ({ row }) => row.getValue("role"),
      }),
      ...(hasUserCompanyDeletePermission
        ? [
            columnHelper.display({
              id: "user-company-action",
              header: () => t`Odebrat`,
              cell: ({ row }) => (
                <UserCompanyAction userCompanyRelationId={row.getValue("id")} />
              ),
            }),
          ]
        : []),
    ],

    [
      hasCompanyApprovePermission,
      hasCompanyRolePermission,
      hasUserCompanyDeletePermission,
    ],
  );
  const userTable = useReactTable({
    data: users.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        userId: false,
        approvalState: false,
        role: false,
      },
    },
  });
  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Uživatelé</Trans> ({users.data.length})
        </h2>
      </summary>
      {users.data.length > 0 ? (
        <Table>
          <Table.Head table={userTable} />
          <Table.Body table={userTable} />
        </Table>
      ) : null}
    </details>
  );
};

const Detail = () => {
  const queryClient = useQueryClient();
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));
  const updateCompany = useUpdateCompanyMutation();

  const handleSubmit = (data: Fields) => {
    updateCompany.mutate(
      {
        id: parseInt(id),
        address: data.address,
        vat: data.vat,
        companyForm: data.legalForm,
        contactName: data.contact.name,
        contactEmail: data.contact.email,
        contactPhone: {
          phoneNumber: data.contact.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(
            data.contact.phone.countryCode,
          ).at(0)!,
        },
        contactPosition: data.contact.role,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Informace</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <CompanyForm
        onSubmit={handleSubmit}
        disabledFields={[]}
        initialValues={getInitialValues(company.data)}
      />
      <Button.Container>
        <Button
          disabled={updateCompany.status === "pending"}
          type="submit"
          variant="primary"
          form="company-form"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </details>
  );
};

type SubscriptionFields = {
  plan: number;
  expiresAt: DateTime;
};

const Subscription = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(companyId) }));
  const plans = useSuspenseQuery(
    companyPlansQuery({ id: parseInt(companyId) }),
  );
  const form = useForm<SubscriptionFields>({
    defaultValues: {
      expiresAt: company.data.planExpiresAt?.toISODate() ?? undefined,
      plan: plans.data.find((plan) => plan.id === company.data.plan?.id)?.id,
    },
  });
  const updateCompanySubscriptionPlan =
    useUpdateCompanySubscriptionPlanMutation();

  const handleSubmit = (data: SubscriptionFields) => {
    updateCompanySubscriptionPlan.mutate(
      {
        companyId: parseInt(companyId),
        plan: {
          plan: data.plan,
          expiresAt: data.expiresAt.toISODate()!,
        },
      },
      {
        onError: resetForm(form),
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Předplatné</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-y-4"
        >
          <div className="flex gap-x-2">
            <label
              className="flex shrink-0 basis-1/2 flex-col"
              htmlFor="phoneNumber"
            >
              <Trans>Předplatné</Trans>
              <select
                {...form.register("plan")}
                className="mt-2 h-[calc(theme(spacing.14)+2px)] rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
              >
                {plans.data.map((plan) => (
                  <option key={plan.id} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </select>
            </label>
            <InputField<SubscriptionFields>
              label={<Trans>Platné do</Trans>}
              name="expiresAt"
              type="date"
              options={{
                setValueAs: (v) => DateTime.fromISO(v),
                required: t`Prosím vyplňte platnost předplatného`,
                validate: (date) => {
                  if (!DateTime.isDateTime(date)) {
                    return t`Zadané datum není platné`;
                  }

                  return (
                    date > DateTime.now() || t`Vyplňte prosím budoucí datum`
                  );
                },
              }}
            />
          </div>
          <Button
            disabled={updateCompanySubscriptionPlan.status === "pending"}
            className="w-full"
            variant="primary"
          >
            <Trans>Uložit</Trans>
          </Button>
        </form>
      </FormProvider>
    </details>
  );
};

const useCompanyId = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "company id is missing");
  return id;
};

export { Company, Users, Detail, Subscription, path };
