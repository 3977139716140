import { msg, t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import starSrc from "@icons/star.svg?url";
import starFilledSrc from "@icons/star-filled.svg?url";
import starOutlineSrc from "@icons/star-outline.svg?url";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { cropPricesQuery } from "./quick-trading.api";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import {
  Navigate,
  createSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import { Spinner } from "@/common/components/spinner/spinner";
import { useCurrency } from "@/common/hooks/use-currency";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { useLocalStorage } from "@/common/hooks/use-local-storage";
import { CropIcon } from "@/common/components/crop-icon/crop-icon";
import { toggleItem } from "@/common/utils/array";
import { partition } from "lodash";
import {
  PriceItem,
  PriceList,
} from "@/common/components/price-list/price-list";
import { PageTitle } from "@/common/components/page-title/page-title";
import { Failed } from "@/common/components/info/info";
import { useLingui } from "@lingui/react";
import { TwoColumnLayout } from "../layout";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { DateTime } from "luxon";
import { WrapMeWith } from "@/common/components/wrap-me-with/wrap-me-with";
import { useSidebar } from "@/common/providers/sidebar-provider";
import { getPricesView } from "@/common/utils/trading";
import { postalCodesQuery } from "../shared/api/get-postal-codes";
import {
  PostalCodeDialog,
  usePostalCodeDialogParams,
} from "../shared/components/postal-code-dialog/postal-code-dialog";

const path = "quick-trading";

const QuickTrading = () => {
  const { marketPriceId } = useParams();
  const isDetailOpen = marketPriceId !== undefined;
  const sidebar = useSidebar();
  const isRightPaneOpen = isDetailOpen || sidebar.state.screen;
  const { _ } = useLingui();
  const { params, enforceDialog } = usePostalCodeDialogParams();
  useScrollbarToggle(params?.enforceDialog);
  const [CurrencySwitch, currency] = useCurrency();
  const postalCodes = useQuery(postalCodesQuery());
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const [favorites, setFavorites] = useLocalStorage<string[]>(
    "purchase-prices.favorites",
    [],
  );

  const cropPrices = useQuery({
    refetchInterval: 60_000,
    ...cropPricesQuery({
      // @ts-expect-error this can hardly ever be undefined, yet I have no idea how to express it here
      longitude: postalCodeObject?.longitude,
      // @ts-expect-error this can hardly ever be undefined, yet I have no idea how to express it here
      latitude: postalCodeObject?.latitude,
      currency: currency as Currency,
    }),
    enabled: !!postalCodeObject,
  });
  const [favoriteCropPrices, otherCropPrices] = partition(
    cropPrices.data,
    (price) => favorites.includes(price.crop.name),
  );

  const toggleFavorite = (cropName: string) => {
    setFavorites((current) => toggleItem(current, cropName));
  };

  if (postalCodes.status === "success" && params?.postalCode) {
    if (
      !postalCodes.data.find(
        (postalCode) => postalCode.code === params?.postalCode,
      )
    ) {
      return <Navigate to="?" />;
    }
  }

  return (
    <TwoColumnLayout
      right={isDetailOpen}
      left={{
        header: (
          <>
            <PageTitle>
              <Trans>Rychlý prodej komodit</Trans>
            </PageTitle>
            {CurrencySwitch}
          </>
        ),
        content: (
          <div className="flex basis-full flex-col">
            <Metadata title={_(msg`Rychlý prodej komodit`)} />
            <Spacer />
            <div className="flex flex-col items-start gap-4 rounded-2xl bg-[#EFF5F4] p-4 text-can-midnight-steel sm:flex-row sm:items-center">
              {postalCodeObject ? (
                <b>
                  <Trans>
                    Ceny pro {postalCodeObject.code}, {postalCodeObject.name}
                  </Trans>
                </b>
              ) : null}
              <small>
                <Trans>
                  Nyní zobrazujeme aktuální ceny komodit pro oblast dle
                  poštovního směrovacího čísla (PSČ)
                </Trans>
              </small>
              <button
                onClick={enforceDialog}
                className="ml-0 w-full flex-shrink rounded-lg border border-can-forest-teal px-4 py-2 text-xs text-can-forest-teal sm:ml-auto sm:max-w-[259px]"
              >
                <Trans>Upravit PSČ</Trans>
              </button>
            </div>
            <Spacer className="h-6" />
            {cropPrices.status === "pending" ? (
              <div className="flex basis-full items-center justify-center">
                <Spinner />
              </div>
            ) : null}
            {cropPrices.status === "error" ? (
              <Failed error={cropPrices.error} />
            ) : null}
            {cropPrices.status === "success" ? (
              <div className="flex flex-col gap-y-8">
                {[
                  {
                    items: favoriteCropPrices,
                    icon: starFilledSrc,
                    iconTitle: t`Odebrat z oblíbených`,
                    title: (
                      <span
                        className="flex items-center gap-2"
                        title="Zaškrtnutím hvězdičky přidáte komoditu do oblíbených"
                      >
                        <img src={starOutlineSrc} />{" "}
                        <Trans>Moje oblíbené komodity</Trans>
                      </span>
                    ),
                    titleTooltip: t`Zaškrtnutím hvězdičky u jednotlivých komodit je přesunete v seznamu výše`,
                  },
                  {
                    items: otherCropPrices,
                    icon: starSrc,
                    iconTitle: t`Přidat do oblíbených`,
                    title: t`Ostatní komodity`,
                  },
                ].map((section) => (
                  <section
                    key={section.iconTitle}
                    className="flex flex-col gap-4"
                  >
                    <h2
                      className="flex items-center gap-2 font-bold text-can-forest-teal"
                      title={section.titleTooltip}
                    >
                      {section.title}
                    </h2>

                    <div
                      className={`grid w-full gap-6 ${isRightPaneOpen ? "grid-cols-1 2xl:grid-cols-2" : "grid-cols-1 xl:grid-cols-3"}`}
                    >
                      {section.items.map((item) => (
                        <section
                          className="flex flex-col gap-y-2 rounded-2xl p-4 shadow-can-light-box"
                          key={item.crop.name}
                        >
                          <h2 className="flex items-center gap-x-2 font-bold text-can-midnight-steel">
                            <CropIcon cropName={item.crop.name} />{" "}
                            {item.crop.name}
                            <button
                              title={section.iconTitle}
                              onClick={() => toggleFavorite(item.crop.name)}
                              className="group -mr-2 ml-auto flex content-center rounded-full p-2 hover:bg-can-silver-gray"
                            >
                              <img
                                src={section.icon}
                                className="group-hover:hidden"
                              />
                              <img
                                src={starOutlineSrc}
                                className="hidden group-hover:inline"
                              />
                            </button>
                          </h2>
                          {getPricesView(item.prices).map((prices, i) => (
                            <PriceList
                              renderPrice={(price) =>
                                price ? (
                                  <WrapMeWith
                                    when={true}
                                    wrapper={(children) => (
                                      <SearchParamsLink
                                        className="flex flex-col gap-y-2 text-can-slate-blue-gray"
                                        to={price.id}
                                      >
                                        {children}
                                      </SearchParamsLink>
                                    )}
                                  >
                                    <PriceItem price={price} />
                                  </WrapMeWith>
                                ) : null
                              }
                              className={
                                i === 0 && prices.length === 1
                                  ? "[&>li]:col-start-4 [&>li]:border-l [&>li]:border-r-0"
                                  : ""
                              }
                              key={i}
                              prices={prices.map((p) =>
                                p?.type === "data"
                                  ? {
                                      id: p.id,
                                      quarter: p.quarter.name,
                                      value: p.price,
                                      currency,
                                    }
                                  : null,
                              )}
                            />
                          ))}
                        </section>
                      ))}
                    </div>
                  </section>
                ))}
              </div>
            ) : null}
            <Spacer />
            {params?.enforceDialog ? <PostalCodeDialog /> : null}
            <Spacer />
            {cropPrices.dataUpdatedAt > 0 ? (
              <p className="mb-8 mt-auto text-center text-sm">
                <Trans>Aktualizováno</Trans>{" "}
                {DateTime.fromMillis(cropPrices.dataUpdatedAt).toLocaleString(
                  DateTime.DATETIME_FULL,
                )}
              </p>
            ) : null}
          </div>
        ),
      }}
    ></TwoColumnLayout>
  );
};

const initialParams = createSearchParams({
  enforceDialog: "true",
  currency: "CZK",
});

const QuickTradingParams = ({
  children,
  params,
}: React.PropsWithChildren<{ params: string }>) => {
  const searchParams = usePostalCodeDialogParams();
  const { pathname } = useLocation();

  if (!searchParams.params) {
    return <Navigate to={`${pathname}?${params}`} />;
  }

  return children;
};

const Restoration = ({
  children,
}: {
  children: (params: string) => React.ReactNode;
}) => {
  const postalCodes = useQuery(postalCodesQuery());
  const { params } = usePostalCodeDialogParams();
  const [savedParams, setSavedParams] = useLocalStorage<string>(path);
  const savedURLParams = createSearchParams(savedParams);
  const postalCode = savedURLParams.get("postalCode");
  const isValidPostalCode =
    postalCodes.data?.find((code) => code.code === postalCode) !== undefined;

  React.useEffect(() => {
    if (!params) {
      return;
    }
    setSavedParams(
      Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&"),
    );
  }, [params, setSavedParams]);

  if (postalCodes.status !== "success") {
    return null;
  }

  return (
    <>{children(isValidPostalCode ? savedParams : initialParams.toString())}</>
  );
};

export { QuickTrading, QuickTradingParams, path, Restoration, getPricesView };
