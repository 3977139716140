import invariant from "tiny-invariant";
import { TrialStrategy } from "./strategies/trial.strategy";
import { FullStrategy } from "./strategies/full.strategy";
import { DateTime, Interval } from "luxon";
import { FeatureMode } from "../feature-mode.service";
import { ReportTo } from "@/generated/api/news";

type Mode = Exclude<FeatureMode, "not-available">;

interface Strategy {
  isMasked: (interval: Interval, date: DateTime) => boolean;
  getDummyReport: () => Partial<ReportTo> | undefined;
}

/**
 * Reports are blurred for users with trial version of the feature.
 */
class ReportsFlow {
  private currentStrategy: Strategy | undefined;
  private strategies: Map<Mode, Strategy>;
  private maskedInterval: Interval;

  constructor() {
    this.maskedInterval = Interval.fromDateTimes(
      DateTime.now().minus({ month: 1 }),
      DateTime.now(),
    );
    this.strategies = new Map<Mode, Strategy>([
      ["trial", new TrialStrategy()],
      ["full", new FullStrategy()],
    ]);
  }

  public setStrategy(mode: Mode) {
    const strategy = this.strategies.get(mode);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(strategy, `Strategy for mode ${mode} does not exist`);
    this.currentStrategy = strategy;
  }

  public getMasked(date: DateTime): boolean {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(this.currentStrategy, "No current strategy available");
    return this.currentStrategy.isMasked(this.maskedInterval, date);
  }

  public getDummyReport() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(this.currentStrategy, "No current strategy available");
    return this.currentStrategy.getDummyReport();
  }
}

export { ReportsFlow };
export type { Strategy, Mode };
