import { Trans } from "@lingui/react/macro";
import {
  FastFields,
  OrderForm,
} from "@/common/components/order-form/order-form";
import { FullscreenModalLayout } from "../layout";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import AngleLeft from "@icons/angle-left.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { Button } from "@/common/components/button/button";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { z } from "zod";
import { useAddFastOrderMutation } from "./quick-trading.detail.api";
import { useCurrency } from "@/common/hooks/use-currency";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { entitySchema } from "@/common/schema/entity";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { path as successPath } from "../shared/routes/success/success";
import { path as failedPath } from "../shared/routes/failed/failed";
import { useContractSignGuard } from "@/common/acl/statutory-guard/contract-sign-guard";
import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { postalCodesQuery } from "../shared/api/get-postal-codes";
import { usePostalCodeDialogParams } from "../shared/components/postal-code-dialog/postal-code-dialog";

const path = "order";
const Order = () => {
  const context = useOrderContext();
  const addFastOrder = useAddFastOrderMutation();
  const [, currency] = useCurrency();
  const navigate = useNavigate();
  const postalCodes = useQuery(postalCodesQuery());
  const { params } = usePostalCodeDialogParams();
  const [searchParams] = useSearchParams();
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(postalCodeObject, "Postal code is not set");
  const handleSubmit: React.ComponentProps<
    typeof OrderForm<FastFields>
  >["onSubmit"] = (data) => {
    addFastOrder.mutate(
      {
        amount: data.tons,
        quarterId: data.quarter,
        harvestYear: data.harvestYear,
        note: data.note,
        lowPrice: data.lowPricePerTon,
        price: data.pricePerTon,
        currency: currency as Currency,
        cropId: context.crop.id,
        companyId: data.company,
        gps: {
          latitude: context.postalCodeObject.latitude,
          longitude: context.postalCodeObject.longitude,
        },
        zip: postalCodeObject.code,
      },
      {
        onError: () => {
          navigate(failedPath + "?" + searchParams.toString());
        },
        onSuccess: (data) => {
          navigate(
            `${data.addFastOrder?.id}/offer/${data.addFastOrder?.offer?.id}/${successPath}?${searchParams.toString()}`,
          );
        },
      },
    );
  };
  const guardedSubmit = useContractSignGuard({ action: handleSubmit });

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to=".." replace>
            <AngleLeft />
          </SearchParamsLink>
        }
      >
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          <Trans>Nastavit příkaz k prodeji</Trans>
        </h1>
        <Spacer className="h-6" />
        <OfferPropertiesList
          properties={[
            {
              label: <Trans>Komodita</Trans>,
              value: context.crop.name,
            },
            {
              label: <Trans>PSČ</Trans>,
              value: context.postalCodeObject.label,
            },
          ]}
        />
        <Spacer className="h-6" />
        <OrderForm<FastFields>
          onSubmit={guardedSubmit}
          initialValues={{
            quarter: context.quarter.id,
            pricePerTon: context.pricePerTon,
            tons: context.amount,
          }}
        >
          <OrderForm.QuarterField />
          <OrderForm.LowPriceField currency={currency} />
          <OrderForm.PriceField currency={currency} />
          <OrderForm.CompanyField />
          <OrderForm.TraderField />
          <OrderForm.AmountField />
          <OrderForm.NoteField />
        </OrderForm>
        <Button.Container>
          <Button type="submit" form={OrderForm.formId} variant="primary">
            <Trans>Nastavit příkaz</Trans>
          </Button>
        </Button.Container>
      </FullscreenModalLayout>
      <Outlet />
    </>
  );
};

const contextSchema = z.object({
  quarter: entitySchema,
  amount: z.number().or(z.nan()).optional(),
  crop: entitySchema,
  pricePerTon: z.number(),
  postalCodeObject: z.object({
    name: z.string(),
    label: z.string(),
    code: z.string(),
    latitude: z.number(),
    longitude: z.number(),
  }),
});

const useOrderContext = () => {
  const context = useOutletContext();

  return contextSchema.parse(context);
};

export { Order, path };
