import {
  CompanyToPlanSource,
  getCompanies1,
  getCountries,
  GetCompanies1Plan,
  PlanToType,
} from "@/generated/api/users";
import { itemsPerPage } from "@/common/components/pagination/pagination";
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

type Company = {
  id: number;
  number: string;
  country: string;
  name: string;
  plan: {
    source: CompanyToPlanSource;
    type: PlanToType;
    expiresAt?: DateTime;
  };
};

const companiesQuery = ({
  country,
  page,
  plan,
  planSource,
  planValid,
  search,
}: {
  country: string;
  page: string;
  plan: GetCompanies1Plan;
  planSource: CompanyToPlanSource;
  planValid?: boolean;
  search: string;
}) =>
  queryOptions({
    queryKey: ["companies", search, plan, planSource, country, planValid, page],
    queryFn: ({ signal }) =>
      getCompanies1(
        {
          size: itemsPerPage.oneHundred.toString(),
          page,
          country,
          planSource,
          // @ts-expect-error types are a bit off
          planValid: planValid ?? "",
          plan,
          search,
          sortCol: "NAME",
        },
        { signal },
      ),
    staleTime: Infinity,
  });

const authorizedCountriesQuery = () =>
  queryOptions({
    queryKey: ["authorized-countries"],
    queryFn: ({ signal }) => getCountries({ signal }),
    select: (countries) => countries.filter((country) => country.isAuthorized),
    staleTime: Infinity,
  });

export { companiesQuery, authorizedCountriesQuery };
export type { Company };
