import { Trans } from "@lingui/react/macro";
import { Link, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import Cross from "@icons/cross.svg?react";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { Metadata } from "@/common/components/metadata/metadata";
import React from "react";
import { UserMetadata } from "@/generated/api/users";

const path = ":id";

type User = {
  email: string;
  phone:
    | {
        countryCode: string;
        phoneNumber: string;
      }
    | undefined;
  name: string;
  ssoUserId: string;
  metadata: UserMetadata;
};

const User = ({
  children,
  user,
}: {
  children?: ((user: User) => React.ReactNode) | React.ReactNode;
  user: User;
}) => {
  const { id } = useParams();
  useScrollbarToggle(!!id);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");

  return (
    <>
      <Metadata title={user.name} />
      <Link className="sm:hidden" to="..">
        <Cross />
      </Link>
      <h1 className="text-xl font-bold">{user.name}</h1>
      <ul className="flex flex-col gap-y-2">
        <li>
          <Trans>Telefon</Trans>
          {": "}
          {user.phone?.countryCode}
          {user.phone?.phoneNumber}
        </li>
        <li>
          <Trans>E-mail</Trans>
          {": "}
          {user.email}
        </li>
        <li>
          <Trans>Jazyk webu</Trans>
          {": "}
          {user.metadata?.can.settings.language}
        </li>
        <li>
          <Trans>Jazyk aktualit</Trans>
          {": "}
          {user.metadata?.can.settings.news.language}
        </li>
        <details>
          <summary>
            <Trans>Metadata</Trans>
          </summary>
          <pre className="max-h-[150px] overflow-auto">
            {JSON.stringify(user.metadata, null, "\t")}
          </pre>
        </details>
        {typeof children === "function"
          ? children(user)
          : children !== undefined
            ? children
            : null}
      </ul>
    </>
  );
};

const useConfirmedAction = () => {
  const rejectRef = React.useRef<() => void>();
  const resolveRef = React.useRef<(value: unknown) => void>();
  const [dialog, setDialog] = React.useState<React.ReactNode | undefined>();

  const runWhenConfirmed =
    (dialog: React.ReactNode, callback: () => void) => () => {
      setDialog(dialog);
      new Promise((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
      })
        .then(callback)
        .catch(() => {})
        .finally(() => setDialog(undefined));
    };

  return { runWhenConfirmed, dialog, setDialog, rejectRef, resolveRef };
};

export { User, path, useConfirmedAction };
