import { t } from "@lingui/core/macro";

/**
 * Represents CORS kind of errors
 */
class NetworkError extends Error {
  constructor(message = t`Chyba spojení`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "NetworkError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "NetworkError";
  }
}

class ClientError extends Error {
  constructor(message = "") {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ClientError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ClientError";
  }
}

class ServerError extends Error {
  constructor(message = "") {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ServerError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ServerError";
  }
}

class UnauthorizedError extends Error {
  constructor(message = t`Pouze pro autentizované uživatele`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "UnauthorizedError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "UnauthorizedError";
  }
}

class ForbiddenError extends Error {
  constructor(message = t`Nedostatečné oprávnění`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ForbiddenError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ForbiddenError";
  }
}

class NotFoundError extends Error {
  constructor(message = t`Bohužel, nic jsme nenašli`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "NotFoundError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "NotFoundError";
  }
}

class TooManyCompaniesError extends Error {
  constructor(message = t`Uživatel přiřazen k více společnostem`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "TooManyCompaniesError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "TooManyCompaniesError";
  }
}

export {
  NetworkError,
  ForbiddenError,
  UnauthorizedError,
  ServerError,
  ClientError,
  NotFoundError,
  TooManyCompaniesError,
};
