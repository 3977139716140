import { Strategy } from "../purchase-prices.flow";

class FullStrategy implements Strategy {
  public getFavorites() {
    return [];
  }

  public isMasked() {
    return false;
  }

  public getMaskedPrice() {
    return undefined;
  }
}

export { FullStrategy };
