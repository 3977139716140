import { Plural, Trans } from "@lingui/react/macro";
import { currencyFormatter } from "@/common/services/formatter";
import { ThreeStepProgressBar } from "../progress-bar/progress-bar";
import { CropIcon } from "@/common/components/crop-icon/crop-icon";

const numberFormatter = new Intl.NumberFormat(undefined);

const CommodityCard = ({ children }: React.PropsWithChildren) => {
  return (
    <article className="flex flex-col gap-2 rounded-2xl p-4 text-xs text-can-slate-blue-gray shadow-can-light-box">
      {children}
    </article>
  );
};

const Available = ({
  available,
  order,
  tons,
}: {
  order: number;
  available: number;
  tons: number;
}) => {
  const _available = numberFormatter.format(available);

  return (
    <p className="text-left">
      <Trans>
        K dispozici volných {_available} z{" "}
        <Plural
          value={tons}
          _0="# tun"
          one="# tuna"
          few="# tuny"
          many="# tun"
          other="# tun"
        />
        .
      </Trans>
      {order > 0 ? (
        <b className="text-[#FF914D]">
          {" "}
          <Trans>
            Aktivní příkazy na{" "}
            <Plural
              value={order}
              _0="# tun"
              one="# tuna"
              few="# tuny"
              many="# tun"
              other="# tun"
            />
            .
          </Trans>
        </b>
      ) : null}
    </p>
  );
};

const Stored = ({ tons = 0 }: { tons?: number }) => {
  return (
    <p className="text-left">
      <Trans>Skladem</Trans>:{" "}
      <Plural value={tons} _0="# tun" one="# tuna" other="# tun" few="# tuny" />
    </p>
  );
};

const Title = ({
  crop,
}: {
  crop: { harvestYear: number; name?: string; mark: boolean };
}) => (
  <h4 className="flex flex-wrap justify-between gap-2 text-base font-bold text-can-midnight-steel">
    <span className="flex items-center gap-2 ">
      <CropIcon className="flex-shrink-0" cropName={crop.name ?? ""} />
      {crop.mark ? (
        <mark className="text-can-slate-blue-gray">{crop.name}</mark>
      ) : (
        <span>{crop.name}</span>
      )}
    </span>
    <span>{crop.harvestYear}</span>
  </h4>
);

const Price = ({
  tons,
  pricePerTon,
  currency,
}: {
  tons?: number;
  pricePerTon?: number;
  currency: "EUR" | "CZK";
}) => (
  <p>
    <Trans>Hodnota celkem (nyní)</Trans>:{" "}
    {pricePerTon !== undefined && tons !== undefined
      ? currencyFormatter(currency).format(pricePerTon * tons)
      : null}{" "}
    |{" "}
    {pricePerTon !== undefined
      ? `${currencyFormatter(currency).format(pricePerTon)}/t`
      : "-"}
  </p>
);

CommodityCard.Available = Available;
CommodityCard.Price = Price;
CommodityCard.Stored = Stored;
CommodityCard.Title = Title;
CommodityCard.ProgressBar = ThreeStepProgressBar;

export { CommodityCard };
