/* DO NOT MODIFY BY HAND */

import { msg } from "@lingui/core/macro";
import invariant from "tiny-invariant";
import type { MessageDescriptor } from "@lingui/core";

const features = new Map<string, MessageDescriptor>();
features.set("market_news_trial_title", msg`market_news_trial_title`)
features.set("market_news_trial_description", msg`market_news_trial_description`)
features.set("market_news_full_title", msg`market_news_full_title`)
features.set("market_news_full_description", msg`market_news_full_description`)
features.set("stock_exchange_full_title", msg`stock_exchange_full_title`)
features.set("stock_exchange_full_description", msg`stock_exchange_full_description`)
features.set("quick_trading_full_title", msg`quick_trading_full_title`)
features.set("quick_trading_full_description", msg`quick_trading_full_description`)
features.set("purchase_prices_trial_title", msg`purchase_prices_trial_title`)
features.set("purchase_prices_trial_description", msg`purchase_prices_trial_description`)
features.set("purchase_prices_full_title", msg`purchase_prices_full_title`)
features.set("purchase_prices_full_description", msg`purchase_prices_full_description`)
features.set("trading_full_title", msg`trading_full_title`)
features.set("trading_full_description", msg`trading_full_description`)
features.set("transactions_orders_full_title", msg`transactions_orders_full_title`)
features.set("transactions_orders_full_description", msg`transactions_orders_full_description`)
features.set("storages_full_title", msg`storages_full_title`)
features.set("storages_full_description", msg`storages_full_description`)

export const getTranslation = (id: string) => {
  const translation = features.get(id)

  invariant(translation)
  return translation
}