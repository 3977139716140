import { t } from "@lingui/core/macro";
import { DateTime } from "luxon";

type Filter = {
  label: string;
  dateFrom?: string;
  dateTo: string;
};

const dynamicFilterCount = 3;
const yyyymmdd = "yyyy-MM-dd";
const today = DateTime.now();
const params = {
  dateFrom: "date-from",
  dateTo: "date-to",
} as const;

const getAvailableFilters = (oldest?: DateTime): Filter[] => {
  const filters = [
    lastMonthFilter(),
    {
      label: t`Poslední 3 měsíce`,
      dateFrom: today.minus({ month: 3 }).toFormat(yyyymmdd),
      dateTo: today.toFormat(yyyymmdd),
    },
    {
      label: t`Poslední rok`,
      dateFrom: today.minus({ year: 1 }).toFormat(yyyymmdd),
      dateTo: today.toFormat(yyyymmdd),
    },
  ];

  if (!oldest) {
    return filters;
  }

  const thisYear = today.get("year");
  const oldestYear = oldest.get("year");

  for (let i = 1; i <= thisYear - oldestYear; i += 1) {
    if (i > dynamicFilterCount) {
      break;
    }
    const previousYear = thisYear - i;
    const yearLabel =
      i === dynamicFilterCount
        ? t`Rok ${previousYear} a starší`
        : t`Rok ${previousYear}`;

    filters.push({
      label: yearLabel,
      dateFrom: today.startOf("year").minus({ year: i }).toFormat(yyyymmdd),
      dateTo: today
        .startOf("year")
        .minus({ year: i })
        .endOf("year")
        .toFormat(yyyymmdd),
    });
  }

  return filters;
};

const getNextFilter = (filters: Filter[], filter: Omit<Filter, "label">) => {
  const next = getFilterByDateRange(filters, filter);
  if (!next) {
    return;
  }

  const position = filters.findIndex((f) => f.label === next.label);

  if (position === filters.length - 1) {
    return;
  }

  return filters[position + 1];
};

const lastMonthFilter = () => ({
  label: t`Poslední měsíc`,
  dateFrom: today.minus({ month: 1 }).toFormat(yyyymmdd),
  dateTo: today.toFormat(yyyymmdd),
});

const getURLParams = (filter: Pick<Filter, "dateFrom" | "dateTo">) => {
  return {
    [params.dateFrom]: filter.dateFrom ?? "",
    [params.dateTo]: filter.dateTo,
  };
};

const getFilterParams = (params: {
  "date-from"?: string;
  "date-to": string;
}) => {
  return {
    dateFrom: params["date-from"],
    dateTo: params["date-to"],
  };
};

const resetURLParams = (urlParams: URLSearchParams) => {
  urlParams.delete(params.dateFrom);
  urlParams.set(params.dateTo, today.toFormat(yyyymmdd));
};

const getFilterByDateRange = (
  filters: Filter[],
  { dateFrom, dateTo }: { dateTo: string; dateFrom?: string },
) => {
  const result = filters.find(
    (filter) => filter.dateTo === dateTo && filter.dateFrom === dateFrom,
  );
  return result;
};

const initialFilter = getURLParams(lastMonthFilter());

export {
  getAvailableFilters,
  initialFilter,
  getURLParams,
  getFilterParams,
  resetURLParams,
  getFilterByDateRange,
  getNextFilter,
};
export type { Filter };
