import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

type PostalCodes = z.infer<typeof postalCodesSchema>;

const postalCodesSchema = z.array(
  z.object({
    code: z.number(),
    name: z.string(),
    latitude: z.number(),
    longitude: z.number(),
  }),
);

const fetchData = async (signal: AbortSignal) => {
  const response = await fetch("/data/postal-codes-cz.json", { signal });
  const json = await response.json();
  const safeJson = postalCodesSchema.parse(json);

  return safeJson;
};

const postalCodesQuery = () =>
  queryOptions({
    queryKey: ["postal-codes"],
    queryFn: ({ signal }) => fetchData(signal),
    staleTime: Infinity,
    select: (data) =>
      data.map(({ code, name, ...rest }) => ({
        ...rest,
        code: code.toString(),
        name,
        label: `${code} ${name}`,
      })),
  });

export { postalCodesQuery };
export type { PostalCodes };
