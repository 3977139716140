import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import { path as stockExchangePath } from "./stock-exchange/stock-exchange";
import { NavigateToInitialPage, Root } from "./root/root";
import { ErrorPage } from "@/common/components/error-page/error-page";
import { Layout } from "./layout";
import { Callback, path as callbackPath } from "./callback/callback";
import * as PurchasePrices from "./purchase-prices/purchase-prices";
import { Anonymous } from "./anonymous/anonymous";
import { Logout } from "./logout/logout";
import * as IncompleteUser from "./incomplete-user/incomplete-user";
import { stockExchangeRoutes, getReportsRoutes } from "./routes.basic";
import {
  tradingRoutes,
  getPurchasePricesRoutes,
  transactionsAndOrdersRoutes,
  storagesRoutes,
  quickTradingRoutes,
} from "./routes.trading";
import { routes as adminRoutes } from "./routes.admin";
import { BlockedUser } from "./blocked-user/blocked-user";
import { Features } from "@/common/providers/feature-provider";
import { PlanSchema } from "@/common/services/user";
import {
  FeatureMode,
  getFeatureMode,
} from "@/domain/plan/feature-mode.service";
import { LockedScreen } from "./shared/components/locked-screen/locked-screen";

type RouteConfig = {
  path: string;
  routes: Record<FeatureMode, RouteObject[]>;
  feature: keyof Features;
};

const createIncompleteUserRouter = () =>
  createBrowserRouter([
    {
      path: IncompleteUser.path,
      element: <IncompleteUser.IncompleteUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <Navigate to={IncompleteUser.path} />,
    },
  ]);

const createBlockedUserRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <BlockedUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ]);

const createAnonymousRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <Anonymous />,
    },
    {
      path: callbackPath,
      element: <Callback />,
    },
  ]);

// TODO import paths from subroutes
const featureRoutes: RouteConfig[] = [
  {
    path: "trades",
    feature: "TRADING",
    routes: {
      full: tradingRoutes,
      trial: [
        {
          element: <LockedScreen />,
          path: "trades/*",
        },
      ],
      "not-available": [],
    },
  },
  {
    path: "quick-trading",
    feature: "QUICK_TRADING",
    routes: {
      full: quickTradingRoutes,
      trial: [{ element: <LockedScreen />, path: "quick-trading/*" }],
      "not-available": [],
    },
  },
  {
    path: "purchase-prices",
    feature: "PURCHASE_PRICES",
    routes: {
      full: getPurchasePricesRoutes("full"),
      trial: getPurchasePricesRoutes("trial"),
      "not-available": [],
    },
  },
  {
    path: "storages",
    feature: "STORAGES",
    routes: {
      full: [storagesRoutes],
      trial: [{ element: <LockedScreen />, path: "storages/*" }],
      "not-available": [],
    },
  },
  {
    path: "transactions-and-orders",
    feature: "TRANSACTIONS_ORDERS",
    routes: {
      full: transactionsAndOrdersRoutes,
      trial: [{ element: <LockedScreen />, path: "transactions-and-orders/*" }],
      "not-available": [],
    },
  },
  {
    path: "stock-exchange",
    feature: "STOCK_EXCHANGE",
    routes: {
      full: stockExchangeRoutes,
      trial: [{ element: <LockedScreen />, path: "stock-exchange/*" }],
      "not-available": [],
    },
  },
  {
    path: "reports",
    feature: "MARKET_NEWS",
    routes: {
      full: getReportsRoutes("full"),
      trial: getReportsRoutes("trial"),
      "not-available": [],
    },
  },
];

const getFeatureRoutes = (
  featureConfig: RouteConfig,
  companyPlan: PlanSchema["planId"],
  features: Features,
): RouteObject[] => {
  const mode = getFeatureMode(companyPlan, features[featureConfig.feature]);
  return featureConfig.routes[mode];
};

const getRoutes = (companyPlan: PlanSchema["planId"], features: Features) => [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/",
    element: (
      <Root>
        <NavigateToInitialPage
          page={
            features.PURCHASE_PRICES ? PurchasePrices.path : stockExchangePath
          }
        />
      </Root>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Callback />,
        path: callbackPath,
      },
      {
        element: <Layout />,
        children: [
          ...featureRoutes.flatMap((config) =>
            getFeatureRoutes(config, companyPlan, features),
          ),
        ],
      },
    ],
  },
  ...adminRoutes(),
];

const createRouter = (companyPlan: PlanSchema["planId"], features: Features) =>
  createBrowserRouter(getRoutes(companyPlan, features));

export {
  createRouter,
  createAnonymousRouter,
  createIncompleteUserRouter,
  createBlockedUserRouter,
  getRoutes,
};
