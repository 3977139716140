import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import React from "react";
import {
  ClientError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  ServerError,
  TooManyCompaniesError,
  UnauthorizedError,
} from "@/api/errors";
import { Spinner } from "@/common/components/spinner/spinner";
import { logger } from "@/common/services/logger";
import { Navigate } from "react-router";

const Info = ({ children }: React.PropsWithChildren<unknown>) => (
  <div role="status" className="flex flex-grow items-center justify-center">
    <div className="text-center text-sm text-can-stormy-sky">{children}</div>
  </div>
);

const Failed = ({ error }: { error: unknown }) => {
  // TODO is there a better way to check for invalid refresh token?
  // 403 error is returned, but it's kinda out of hand (comes from the auth0 SDK)
  // there is an `error` property on auth0 error
  if (
    error instanceof Error &&
    // eslint-disable-next-line lingui/no-unlocalized-strings
    error.message.toLowerCase().includes("refresh token")
  ) {
    return <Navigate to="/logout" />;
  }

  let message = t`Něco se pokazilo`;
  const eventId = logger.error(error);
  if (error instanceof Error) {
    switch (error.name) {
      case ForbiddenError.getName():
      case UnauthorizedError.getName():
      case NetworkError.getName():
      case NotFoundError.getName():
      case ClientError.getName():
      case TooManyCompaniesError.getName():
      case ServerError.getName(): {
        if (error.message) {
          message = error.message;
        }
      }
    }
  }

  return (
    <Info>
      {message}
      {showSentryEventId(error) && eventId ? (
        <>
          <p>
            <Trans>
              Při komunikaci s podporou prosím uveďte následující ID chyby:
            </Trans>
          </p>
          <pre className="my-4 block rounded-lg bg-can-silver-gray p-2 text-center">
            {eventId}
          </pre>
        </>
      ) : null}
    </Info>
  );
};

const NoData = ({ children }: React.PropsWithChildren) => (
  <Info>
    {children ?? t`Pro zvolené časové období nejsou k dispozici žádná data.`}
  </Info>
);

const Pending = () => (
  <Info>
    <Spinner />
  </Info>
);

const showSentryEventId = (error: unknown) => {
  if (error instanceof Error) {
    if (error.name === UnauthorizedError.getName()) {
      return false;
    }
  }

  return true;
};

export { Failed, NoData, Pending, Info };
