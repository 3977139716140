import { PlanSchema } from "@/common/services/user";
import { FullStrategy } from "./strategies/full.strategy";
import { TrialStrategy } from "./strategies/trial.strategy";
import { NotAvailableStrategy } from "./strategies/not-available.strategy";
import { LinkWithFeature } from "@/common/components/nav-bar/nav-bar";
import React, { ComponentType } from "react";
import { Features } from "@/common/providers/feature-provider";

type Mode = "full" | "trial" | "not-available";
type WrapperComponent =
  | ComponentType<{ children: React.ReactNode }>
  | typeof React.Fragment;

interface Strategy {
  wrapWith(): null | WrapperComponent;
}

class MenuItemFlow {
  private item: LinkWithFeature;
  private plan: PlanSchema & { features: Features };
  private currentStrategy: Strategy;
  private strategies: Map<Mode, Strategy>;

  constructor(
    item: LinkWithFeature,
    plan: PlanSchema & { features: Features },
  ) {
    this.plan = plan;
    this.item = item;
    this.strategies = new Map<Mode, Strategy>([
      ["full", new FullStrategy()],
      ["trial", new TrialStrategy()],
      ["not-available", new NotAvailableStrategy()],
    ]);
    this.currentStrategy = this.getStrategy();
  }

  private getStrategy() {
    const feature = this.plan.features[this.item.feature];
    const plan = this.plan.planId;

    if (!feature) {
      return this.getStrategyOrThrow("not-available");
    }

    if (!feature.menu) {
      return this.getStrategyOrThrow("not-available");
    }

    if (feature.planId === plan) {
      return this.getStrategyOrThrow("full");
    }

    if (feature.planId !== plan) {
      return this.getStrategyOrThrow("trial");
    }

    throw new Error(
      // eslint-disable-next-line lingui/no-unlocalized-strings
      `No strategy found for feature: ${JSON.stringify(feature)} and plan ${plan}`,
    );
  }

  public getWrapper() {
    return this.currentStrategy.wrapWith();
  }

  private getStrategyOrThrow(mode: Mode): Strategy {
    const strategy = this.strategies.get(mode);
    if (!strategy) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Strategy ${mode} not found`);
    }
    return strategy;
  }
}

export { MenuItemFlow };
export type { Strategy };
