import { Trans } from "@lingui/react/macro";
import { toaster } from "@/common/components/toast/toast";
import { FieldValues, UseFormReturn } from "react-hook-form";

const resetForm =
  <TFieldValues extends FieldValues = FieldValues>(
    form: UseFormReturn<TFieldValues>,
  ) =>
  () => {
    form.reset();
    toaster.error(
      {
        title: <Trans>Akce se nezdařila</Trans>,
      },
      {
        autoClose: 2000,
      },
    );
  };

export { resetForm };
