import { useAuth0 } from "@auth0/auth0-react";
import invariant from "tiny-invariant";
import { getUser, UserSchema } from "../services/user";

const useFirstCompany = () => {
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);

  return getFirstCompany(user);
};

const getFirstCompany = (user: UserSchema) => {
  const company = user["can/app_metadata"]?.companies?.at(0);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(company, `User has no company defined`);
  return company;
};

export { useFirstCompany, getFirstCompany };
