import { Trans } from "@lingui/react/macro";
import { NoData } from "../info/info";

const TooManyCompanies = ({
  children,
  companies,
}: React.PropsWithChildren<{ companies?: Array<unknown> }>) => {
  if (!companies) {
    return null;
  }

  if (companies.length > 1) {
    return (
      <NoData>
        <Trans>
          Váš profil je spojen s více společnostmi. Momentálně si nejsme jisti,
          která ze společností je smluvní stranou obchodu.
          <br />
          Kontaktujte prosím podporu.
        </Trans>
      </NoData>
    );
  }

  return children;
};

export { TooManyCompanies };
