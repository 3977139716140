import { Trans } from "@lingui/react/macro";
import {
  CommodityRatesToCommodity,
  GetCommodityCurrentCurrency,
} from "@/generated/api/prices";
import { useActiveFilter } from "./stock-exchange.hooks";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  CommodityOutlookQueryResult,
  commodityOutlookQuery,
} from "./stock-exchange.api";
import { DateTime, Settings } from "luxon";
import { currencyFormatter } from "@/common/services/formatter";
import { Chart } from "@/common/components/chart/chart";

type Props = {
  basePrice?: number;
  commodityId: Extract<CommodityRatesToCommodity, "WHEAT" | "RAPESEED">;
};

const Outlook = ({ commodityId }: Props) => {
  const commodityIdParam = commodityId.toLowerCase() as Lowercase<
    typeof commodityId
  >;
  const activeFilter = useActiveFilter(commodityIdParam);
  const outlook = useSuspenseQuery(
    commodityOutlookQuery({
      commodity: commodityId,
      currency: GetCommodityCurrentCurrency.EUR,
      range: activeFilter.value,
      dateRange: {
        from:
          activeFilter.value === "all"
            ? undefined
            : DateTime.fromISO(activeFilter.dateFrom),
        to:
          activeFilter.value === "all"
            ? undefined
            : DateTime.fromISO(activeFilter.dateTo),
      },
    }),
  );

  return (
    <ul>
      {outlook.data
        .slice(0, 6)
        .map(getOutlookItem)
        .map(({ key, isHighlighted, notLast, date, quarter }) => {
          return (
            <li
              key={key}
              className={`mr-6 grid grid-cols-3 py-2 text-xs md:text-base  ${
                notLast ? "border-b border-b-can-silver-cloud" : ""
              } ${isHighlighted ? "font-bold text-can-midnight-steel" : ""}`}
            >
              <span className="flex content-center items-center gap-x-2">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {date}
                </span>
                {quarter.period === "CURRENT" ? <CurrentLegend /> : null}
                {quarter.period === "NEXT" ? <NextLegend /> : null}
              </span>
              <span className="mx-auto">
                {currencyFormatter(GetCommodityCurrentCurrency.EUR).format(
                  quarter.rate,
                )}
                <Trans context="unit of weight">/t</Trans>
              </span>
              {typeof quarter.trend === "number" ? (
                <Chart.Trend
                  currency={GetCommodityCurrentCurrency.EUR}
                  className={`ml-auto ${
                    isHighlighted ? "font-bold" : "font-normal"
                  }`}
                >
                  {quarter.trend}
                </Chart.Trend>
              ) : (
                <span className="ml-auto">{quarter.trend}</span>
              )}
            </li>
          );
        })}
    </ul>
  );
};

const CurrentLegend = () => (
  <span className="flex h-2 w-4 flex-col">
    <span className="h-0.5 w-4 bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua"></span>
    <span className="h-1.5 w-4 bg-[#BFDBED]"></span>
  </span>
);

const NextLegend = () => (
  <span className="h-2 w-4 border-t-2 border-dotted border-can-forest-teal bg-can-silver-gray"></span>
);

const getOutlookItem = (
  quarter: CommodityOutlookQueryResult[number],
  i: number,
  arr: (typeof quarter)[],
) => {
  const key = `${quarter.month}-${quarter.year}`;
  const isHighlighted = !!quarter.period;
  const notLast = i < arr.length - 1;
  const firstOfMonth = DateTime.fromFormat(
    `${quarter.month} 01 ${quarter.year}`,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "LLLL dd yyyy",
    {
      locale: "en-US", // the month names are stored in english on the backend, would fail with any other locale
    },
  );
  const month = firstOfMonth.toLocaleString(
    {
      month: "long",
    },
    {
      locale: Settings.defaultLocale,
    },
  );
  const year = firstOfMonth.toLocaleString({ year: "numeric" });
  const date = `${month} ${year} (${quarter.quarter})`;

  return { key, isHighlighted, notLast, date, quarter };
};

export { Outlook };
