import { Trans } from "@lingui/react/macro";
import { useToggle } from "@/common/hooks/use-toggle";
import React from "react";
import { z } from "zod";
import Cross from "@icons/cross.svg?react";
import { Button } from "../button/button";

/**
 * Since `beforeinstallprompt` event is not supported in every browser (it's not implemented in Firefox for example),
 * the installer itself won't be available everywhere.
 *
 * And this is great, because we don't have to take care of it.
 */
const PWAInstaller = () => {
  const { on: hidden, setOn: hide, setOff: show } = useToggle(true);
  const { on: dismissed, setOn: dismiss } = useToggle();
  const installPrompt = React.useRef<Event>();

  React.useEffect(() => {
    const handler = (event: Event) => {
      if (dismissed) {
        return;
      }

      event.preventDefault();
      installPrompt.current = event;
      show();
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, [dismissed, show]);

  React.useEffect(() => {
    window.addEventListener("appinstalled", hide);

    return () => {
      window.removeEventListener("appinstalled", hide);
    };
  }, [hide]);

  const handleInstallation = async () => {
    if (!installPrompt.current) {
      return;
    }

    const safePrompt = installPromptSchema.parse(installPrompt.current);
    const result = await safePrompt.prompt.apply(installPrompt.current);

    if (result.outcome === "dismissed") {
      dismiss();
    }

    installPrompt.current = undefined;
    hide();
  };

  return hidden || dismissed ? null : (
    <div className="fixed z-[100] flex w-full items-center justify-between bg-white px-6 py-2 shadow-can-light-box sm:left-[calc(theme(spacing.20)-theme(spacing.2))] sm:right-0 sm:h-[72px]  sm:w-auto [&+div.can-top-bar]:top-[64px] [&~div.can-sm-layout]:pt-[84px] [&~div.sm-can-top-bar]:top-[64px]">
      <Button variant="primary" className="flex" onClick={handleInstallation}>
        <Trans>Instalovat</Trans>
      </Button>
      <button className="-mr-2 p-2" onClick={hide}>
        <Cross className="h-4 w-4" />
      </button>
    </div>
  );
};

const installPromptSchema = z.object({
  prompt: z.function().returns(
    z.promise(
      z.object({
        outcome: z.union([z.literal("dismissed"), z.literal("accepted")]),
      }),
    ),
  ),
});

export { PWAInstaller };
