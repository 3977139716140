import { Trans } from "@lingui/react/macro";
import { Logo } from "@/common/components/logo/logo";
import { Spacer } from "../spacer/spacer";
import { NavBar, getClassName } from "../nav-bar/nav-bar";
import ArrowAltToRight from "@icons/arrow-alt-to-right.svg?react";
import AdminUser from "@icons/admin-user.svg?react";
import { useToggle } from "@/common/hooks/use-toggle";
import { Link, NavLink } from "react-router-dom";
import { AdminGuard } from "@/common/acl/guard/guard";
import { useFirstCompany } from "@/common/hooks/use-first-company";
import { FeatureProvider } from "@/common/providers/feature-provider";

const SideBar = () => {
  const expanded = useToggle();
  const company = useFirstCompany();

  return (
    <div
      className={`fixed top-0 hidden h-screen ${expanded.on ? "z-[100] w-[249px] [&>a]:-ml-[177px]" : "w-[72px]"} flex-col items-center sm:flex`}
    >
      <Logo />
      <div className={`flex w-full flex-grow flex-col bg-can-forest-teal`}>
        <Spacer className="h-0.5 w-full bg-white" />
        <Spacer className="h-8" />
        <FeatureProvider companyId={company.companyId}>
          {(features) => (
            <NavBar
              plan={{ ...company.plan, features }}
              renderItem={
                expanded.on
                  ? (link, Wrapper) => (
                      <NavLink
                        className={({ isActive }) =>
                          `relative !justify-start self-start ${itemClassName} [&_svg]:flex-shrink-0 ${getClassName({ isActive })}`
                        }
                        to={link.to}
                      >
                        <Wrapper>{link.icon}</Wrapper>
                        {link.label}
                      </NavLink>
                    )
                  : undefined
              }
            />
          )}
        </FeatureProvider>
        <AdminGuard>
          <Link
            to="/admin"
            target="_blank"
            className={`${itemClassName} ${getClassName({ isActive: false })} ${expanded.on ? "!justify-start" : ""}`}
          >
            <AdminUser />
          </Link>
        </AdminGuard>
        <button
          onClick={expanded.toggle}
          className={`mb-8 w-full ${itemClassName} self-center font-bold text-[#85C4B3]`}
        >
          <ArrowAltToRight className={expanded.on ? "rotate-180" : ""} />
          {expanded.on ? (
            <span>
              <Trans>Zobrazit jen ikony</Trans>
            </span>
          ) : null}
        </button>
      </div>
    </div>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const itemClassName = "gap-4 px-6 flex";

export { SideBar };
