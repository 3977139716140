import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import MenuBurger from "@icons/menu-burger.svg?react";
import { Logo } from "../logo/logo";
import { NavBar, getClassName } from "../nav-bar/nav-bar";
import { useLazyScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useToggle } from "@/common/hooks/use-toggle";
import Cross from "@icons/cross.svg?react";
import { useAuth0 } from "@auth0/auth0-react";
import CircleUser from "@icons/circle-user.svg?react";
import { useSidebar } from "@/common/providers/sidebar-provider";
import User from "@icons/user.svg?react";
import { secondary } from "@/common/components/button/button";
import React from "react";
import { NotificationBell } from "../push-notifications/push-notifications";
import { useFirstCompany } from "@/common/hooks/use-first-company";
import { FeatureProvider } from "@/common/providers/feature-provider";

const TopBar = () => {
  const toggleBodyScrollbar = useLazyScrollbarToggle();
  const sidebar = useSidebar();
  const { user } = useAuth0();
  const expanded = useToggle();
  const expandedProfile = useToggle();
  const { pathname } = useLocation();
  const company = useFirstCompany();
  const close = expanded.setOff;

  React.useEffect(() => {
    close();
  }, [pathname, close]);

  const handleToggle = () => {
    expanded.toggle();
    toggleBodyScrollbar(!expanded.on);
  };

  const handleToggleProfile = () => {
    expandedProfile.toggle();
  };

  const handleOpenProfileSettings = () => {
    expandedProfile.toggle();
    expanded.toggle();
    sidebar.dispatch({ type: "open", screen: "user-profile" });
  };

  return (
    <div className="can-top-bar fixed z-[1] flex w-full justify-between bg-white sm:hidden">
      {expanded.on ? null : <Logo className="ml-6 md:hidden" />}
      <div
        className={`flex basis-full flex-wrap items-center justify-between gap-6 ${expanded.on ? "mt-5" : ""}`}
      >
        {expanded.on ? (
          <>
            <button
              onClick={handleToggleProfile}
              className="ml-6 flex gap-2 font-bold text-can-forest-teal"
            >
              <CircleUser /> {user?.name}
            </button>
            <button
              data-testid="mobile-menu-toggle"
              aria-label={t`Zavřít`}
              onClick={handleToggle}
              className="mr-6"
            >
              <Cross />
            </button>
            {expandedProfile.on ? (
              <nav className="z-10 basis-full bg-white px-6 font-bold text-can-forest-teal">
                <ul className="flex flex-col gap-8">
                  <li>
                    <button
                      onClick={handleOpenProfileSettings}
                      className="flex w-full gap-4"
                    >
                      <User />
                      <Trans>Profil</Trans>
                    </button>
                  </li>
                  <li>
                    <Link className={`${secondary} block w-full`} to="/logout">
                      <Trans>Odhlásit se</Trans>
                    </Link>
                  </li>
                </ul>
              </nav>
            ) : null}
          </>
        ) : (
          <div className="ml-auto mt-4">
            <NotificationBell />
            <button
              data-testid="mobile-menu-toggle"
              onClick={handleToggle}
              className="ml-6"
            >
              <MenuBurger className="mr-6 h-6 w-6" />
            </button>
          </div>
        )}
        {expanded.on ? (
          <aside className="z-[1] flex h-[100dvh] w-full flex-col bg-can-forest-teal pt-6">
            <FeatureProvider companyId={company.companyId}>
              {(features) => (
                <NavBar
                  plan={{ ...company.plan, features }}
                  renderItem={(link, Wrapper) => (
                    <NavLink
                      viewTransition
                      key={link.to}
                      className={({ isActive }) =>
                        `!justify-start gap-4 px-6 ${getClassName({ isActive })}`
                      }
                      to={link.to}
                    >
                      <Wrapper>{link.icon}</Wrapper>
                      {link.label}
                    </NavLink>
                  )}
                />
              )}
            </FeatureProvider>
          </aside>
        ) : null}
      </div>
    </div>
  );
};

export { TopBar };
