import { Trans } from "@lingui/react/macro";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import Cross from "@icons/cross.svg?react";
import Document from "@icons/document.svg?react";
import Download from "@icons/download.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { useSuspenseQuery } from "@tanstack/react-query";
import { contractQuery } from "./contracts.api";
import { Outlet, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import {
  currencyFormatter,
  numberFormatter,
} from "@/common/services/formatter";
import { DateTime } from "luxon";
import { Button, primary } from "@/common/components/button/button";
import { path as signaturePath } from "./transactions-and-orders.signature";
import { ContractStatus } from "@/generated/digitalnisklady.cz/graphql";
import { Info } from "@/common/components/info/info";

const path = "transactions/:contractId";
const Contract = () => {
  const { contractId } = useParams();
  invariant(contractId);
  const contract = useSuspenseQuery(contractQuery({ id: contractId }));

  let ButtonJsx = (
    <SearchParamsLink to={signaturePath} className={primary}>
      <Trans>Podepsat smlouvu</Trans>
    </SearchParamsLink>
  );

  if (contract.data.status === ContractStatus.Failed) {
    ButtonJsx = (
      <Info>
        <Trans>Odmítnuto obchodníkem</Trans>
      </Info>
    );
  }

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to="..">
            <Cross />
          </SearchParamsLink>
        }
      >
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          <Trans>Detail transakce</Trans>
        </h1>
        <OfferPropertiesList
          properties={[
            {
              label: <Trans>Cena</Trans>,
              value: currencyFormatter(contract.data.currency).format(
                contract.data.finalPrice,
              ),
            },
            {
              label: <Trans>Množství</Trans>,
              value: `${numberFormatter().format(contract.data.amount)} tun`,
            },
            {
              label: <Trans>Komodita</Trans>,
              value: contract.data.crop.name,
            },
            {
              label: <Trans>Obchodník</Trans>,
              value: <Trans>TOP EKOS</Trans>,
            },
            {
              label: <Trans>Částka</Trans>,
              value: currencyFormatter(contract.data.currency).format(
                contract.data.amount * contract.data.finalPrice,
              ),
            },
            {
              label: <Trans>Typ</Trans>,
              value: <Trans>Prodej</Trans>,
            },
            {
              label: <Trans>Datum a čas</Trans>,
              value: contract.data.timeCreated.toLocaleString(
                DateTime.DATETIME_SHORT,
              ),
            },
            {
              label: <Trans>Farma</Trans>,
              value: contract.data.company.name,
            },
            {
              label: <Trans>Sklad</Trans>,
              value: contract.data.storage.name,
            },
            {
              label: <Trans>Vlastní doprava</Trans>,
              value: contract.data.customTransport ? (
                <Trans>Ano</Trans>
              ) : (
                <Trans>Ne</Trans>
              ),
            },
            {
              label: <Trans>Poznámka</Trans>,
              value:
                contract.data.note?.length === 0 ? "-" : contract.data.note,
            },
          ]}
        />
        {contract.data.confirmation?.completed ? (
          <ul className="mt-6 gap-y-2">
            {contract.data.confirmation.documents.map((document) => (
              <li className="flex list-none" key={document.url}>
                <a
                  className="flex basis-full items-center gap-x-2 rounded-2xl bg-can-silver-gray px-4 py-3 font-bold text-can-forest-teal"
                  href={document.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Document />
                  <Trans>Smlouva o prodeji</Trans>
                  <Download className="ml-auto" />
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <Button.Container>{ButtonJsx}</Button.Container>
        )}
      </FullscreenModalLayout>
      <Outlet />
    </>
  );
};

export { Contract, path };
